import * as React from "react"

function IconMetatags(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M1 13V4h11.333L15 8.5 12.333 13H1z" stroke="#35383B" />
            <path d="M6 6L4 8.5 6 11M8 11l2-2.5L8 6" stroke="#35383B" />
        </svg>
    )
}

export default IconMetatags
