import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ContentEditor, Header } from '../../components';
import { Block, Button, Text } from '../../components/layout';
import { SvgIconArrowLeft } from '../../svgs';
import { store } from '../../utils/store';
import toast from '../../utils/toast';

function JobOfferEdit() {
    const history = useHistory();
    let { jobOfferId: jobOfferIdFromUrl } = useParams();
    const { languages } = useContext(store);
    const [jobOfferId, setJobOfferId] = useState(jobOfferIdFromUrl);
    const [title, setTitle] = useState(null);
    const [result, setResult] = useState(null);

    let submitForm = null;

    const [queryJobOffer, { loading }] = useLazyQuery(
        gql`
            query jobOffer($id: ID!) {
                jobOffer(id: $id) {
                    id
                    reference
                    status
                    title
                    translations {
                        title
                        local
                        description
                        language {
                            id
                            name
                        }
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.jobOffer) {
                    const aux = {
                        general: {
                            status: data.jobOffer.status,
                            reference: data.jobOffer.reference
                        }
                    };

                    for (let i = 0; i < data.jobOffer.translations.length; i++) {
                        const translation = data.jobOffer.translations[i];
                        aux[`translation-${translation.language.id}`] = translation;
                    }

                    setResult(aux);
                    setTitle(data.jobOffer.title);
                }
            }
        }
    );

    const [mutationJobOfferCreate, { loading: loadingJobOfferCreate }] = useMutation(
        gql`
            mutation jobOfferCreate($reference: String!, $status: JobOfferStatus!, $translations: [JobOfferTranslationInput!]!) {
                jobOfferCreate(reference: $reference, status: $status, translations: $translations) {
                    id
                    reference
                    status
                    title
                    translations {
                        title
                        local
                        description
                        language {
                            id
                            name
                        }
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.jobOfferCreate) {
                    const aux = {
                        general: {
                            status: data.jobOfferCreate.status,
                            reference: data.jobOfferCreate.reference
                        }
                    };

                    for (let i = 0; i < data.jobOfferCreate.translations.length; i++) {
                        const translation = data.jobOfferCreate.translations[i];
                        aux[`translation-${translation.language.id}`] = translation;
                    }

                    setJobOfferId(data.jobOfferCreate.id);
                    setResult(aux);
                    setTitle(data.jobOfferCreate.title);

                    toast.success(`Saved`);
                }
            }
        }
    );

    const [mutationJobOfferUpdate, { loading: loadingJobOfferUpdate }] = useMutation(
        gql`
            mutation jobOfferUpdate($id: ID!, $reference: String!, $status: JobOfferStatus!, $translations: [JobOfferTranslationInput!]!) {
                jobOfferUpdate(id: $id, reference: $reference, status: $status, translations: $translations) {
                    id
                    reference
                    status
                    title
                    translations {
                        title
                        local
                        description
                        language {
                            id
                            name
                        }
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.jobOfferUpdate) {
                    const aux = {
                        general: {
                            status: data.jobOfferUpdate.status,
                            reference: data.jobOfferUpdate.reference
                        }
                    };

                    for (let i = 0; i < data.jobOfferUpdate.translations.length; i++) {
                        const translation = data.jobOfferUpdate.translations[i];
                        aux[`translation-${translation.language.id}`] = translation;
                    }

                    setResult(aux);
                    setTitle(data.jobOfferUpdate.title);

                    toast.success(`Saved`);
                }
            }
        }
    );

    const mutate = (values) => {
        const translations = Object.keys(values).filter((key) => key.includes("translation")).map((key) => ({
            title: values[key].title,
            local: values[key].local,
            description: values[key].description,
            language: key.split("-")[1]
        }));

        if (jobOfferId) {
            mutationJobOfferUpdate({
                variables: {
                    id: jobOfferId,
                    reference: values.general.reference,
                    status: values.general.status,
                    translations
                }
            })
        } else {
            mutationJobOfferCreate({
                variables: {
                    reference: values.general.reference,
                    status: values.general.status,
                    translations
                }
            })
        }
    }

    useEffect(() => {
        if (jobOfferId) {
            queryJobOffer({ variables: { id: jobOfferId } });
        } else {
            setResult({});
        }
    }, []);

    return (
        <>
            <Block flex height="100%">
                <Header
                    leftChildren={
                        <Block height="100%" row>
                            <Block width={52} height={52} center middle pointer onClick={() => history.push("/dashboard/job-offers")}>
                                <SvgIconArrowLeft />
                            </Block>
                            <Block width={1} height="100%" color="greyAlmostWhite" />
                            <Block width={229} height="100%" middle>
                                <Text size={18} height={32} ml={24}>{!title ? "Untitled" : title}</Text>
                            </Block>
                            <Block width={1} height="100%" color="greyAlmostWhite" />
                        </Block>
                    }
                    rightChildren={
                        <Block row>
                            <Button style={{ minWidth: 309 }} text={!!jobOfferId ? "Save" : "Create"} onClick={() => { submitForm(); }} loading={loadingJobOfferUpdate || loadingJobOfferCreate} />
                        </Block>
                    }
                />
                <Block flex pl={230} pt={64} pb={64} style={{ overflow: "auto" }}>
                    {result && <ContentEditor
                        onSubmit={(values) => mutate(values)}
                        bindSubmitForm={(method) => submitForm = method}
                        values={result}
                        structure={{
                            sections: [
                                {
                                    key: "general",
                                    title: "General",
                                    properties: {
                                        fields: [
                                            {
                                                key: "status",
                                                title: "Status",
                                                type: "Status",
                                                properties: {
                                                    required: true,
                                                    options: [
                                                        { label: "Enabled", value: "ENABLED" },
                                                        { label: "Disabled", value: "DISABLED" },
                                                    ]
                                                }
                                            },
                                            {
                                                key: "reference",
                                                title: "Reference",
                                                type: "TextLine",
                                                properties: {
                                                    required: true
                                                }
                                            }
                                        ]
                                    }
                                },
                                ...languages.map((language) => (
                                    {
                                        key: `translation-${language.id}`,
                                        title: language.name,
                                        properties: {
                                            fields: [
                                                {
                                                    key: "title",
                                                    title: "Title",
                                                    type: "TextLine",
                                                    properties: {
                                                        required: false
                                                    }
                                                },
                                                {
                                                    key: "local",
                                                    title: "Local",
                                                    type: "TextLine",
                                                    properties: {
                                                        required: false
                                                    }
                                                },
                                                {
                                                    key: "description",
                                                    title: "Description",
                                                    type: "TextEditor",
                                                    properties: {
                                                        required: false
                                                    }
                                                }
                                            ]
                                        }
                                    }
                                ))
                            ]
                        }}
                    />}
                </Block>
            </Block>
        </>
    );
}

export default JobOfferEdit;