import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Modal, Table } from '../../../../components';
import { Block, Button, CheckBox, Text, TextInput } from '../../../../components/layout';
import ButtonAction from '../../../../components/Table/components/ButtonAction';
import { SvgIconEdit } from '../../../../svgs';
import { store } from '../../../../utils/store';
import validateFormSchema from '../../../../utils/validateFormSchema';

function EmailSettings({ }) {
    const { language } = useContext(store);
    const [updateData, setUpdateData] = useState(null);
    const [settings, setSettings] = useState({});

    const [querySetting, { loading }] = useLazyQuery(
        gql`
            query {
                setting(key: "emailSettings") {
                    key
                    value
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.setting) {
                    setSettings(data.setting.value);
                }
            }
        }
    );

    const [mutationSettingUpdate, { loading: loadingSettingUpdate }] = useMutation(
        gql`
            mutation settingUpdate($value: Json!) {
                settingUpdate(key: "emailSettings", value: $value) {
                    key
                    value
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.settingUpdate) {
                    setUpdateData(null);
                    querySetting();
                }
            }
        }
    );

    useEffect(() => {
        querySetting();
    }, []);

    useEffect(() => {
        querySetting();
    }, [language]);

    return (
        <>
            <Block color="white" pb={28}>
                <Block row center pl={20} pt={10} pb={10}>
                    <Text size={18} height={32}>Email Settings</Text>
                </Block>
                <Block width="100%" height={1} color="greyAlmostWhite" />
                <Block>
                    <Table
                        type={2}
                        loading={loading}
                        columns={[
                            { label: "Host", key: "host" },
                            { label: "Port", key: "port" },
                            { label: "User", key: "user" }
                        ]}
                        rows={
                            [
                                {
                                    host: settings.host,
                                    port: settings.port,
                                    user: settings.user,
                                    actions: <ButtonAction icon={<SvgIconEdit />} onClick={() => setUpdateData(settings)} />
                                }
                            ]
                        }
                    />
                </Block>
            </Block>
            <Modal
                title="Update"
                isOpen={!!updateData}
                onRequestClose={() => setUpdateData(null)}
            >
                <Text size={14} height={20}>Email Settings</Text>
                <Block mt={28}>
                    <Formik
                        enableReinitialize
                        initialValues={settings}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    host: yup.string().required(),
                                    port: yup.number().required(),
                                    user: yup.string().required(),
                                    password: yup.string().required()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationSettingUpdate({ variables: { value: values } });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <TextInput name="host" label="Host" type="text" onChange={handleChange} onBlur={handleBlur} value={values.host} error={errors.host} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="port" label="Port" type="number" onChange={handleChange} onBlur={handleBlur} value={values.port} error={errors.port} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="user" label="User" type="text" onChange={handleChange} onBlur={handleBlur} value={values.user} error={errors.user} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="password" label="Password" type="password" onChange={handleChange} onBlur={handleBlur} value={values.password} error={errors.password} />
                                            </Block>
                                            <Block mt={12}>
                                                <CheckBox name="secure" label="Secure" onChange={handleChange} onBlur={handleBlur} value={values.secure} error={errors.secure} />
                                            </Block>
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingSettingUpdate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
        </>
    );
}

export default EmailSettings;