export default {
    greyDarker: "#222527",
    greyDark: "#35383B",
    grey: "#5D6368",
    greyLight: "#B2B6B9",
    greyLightest: "#D8DADC",
    greyAlmostWhite: "#EBECED",
    greyWhite: "#F9FAFA",
    greyNotWhite: "#F5F5F5",
    purple01: "#3127AF",
    purple02: "#412ECC",
    purple03: "#4C40ED",
    purple04: "#5555FF",
    green01: "#42BAA8",
    green02: "#4CD6C5",
    red01: "#E31C46",
    grey01: "#444444",
    grey02: "#707070",
    grey03: "#A7A7A7",
    grey04: "#DADADA",
    grey05: "#F4F4F4",
}