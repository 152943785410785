import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { Modal, ModalThatSlides, Table } from '../../../../components';
import { Block, Button, Text } from '../../../../components/layout';
import ButtonAction from '../../../../components/Table/components/ButtonAction';
import { SvgIconCheck, SvgIconModalDelete, SvgIconTrash } from '../../../../svgs';
import HasScope from '../../../../utils/HasScope';

function ArticlesTemplates({ }) {
    const [modalAddIsOpen, setModalAddIsOpen] = useState(false);
    const [modalRemoveIsOpen, setModalRemoveIsOpen] = useState(null);
    const [articlesTemplates, setArticlesTemplates] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const [queryArticlesTemplates, { loading }] = useLazyQuery(
        gql`
            query {
                articlesTemplates {
                    id
                    name
                }
                templates(first: 1000) {
                    edges {
                        node {
                            id
                            name
                        }
                        cursor
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.articlesTemplates) {
                    setArticlesTemplates(data.articlesTemplates);
                    setTemplates(data.templates.edges.filter((edge) => !data.articlesTemplates.find((articlesTemplate) => articlesTemplate.id === edge.cursor)));
                }
            }
        }
    );

    const [mutationArticlesTemplateAdd, { loading: loadingArticlesTemplateAdd }] = useMutation(
        gql`
            mutation articlesTemplateAdd($id: ID!) {
                articlesTemplateAdd(id: $id) {
                    id
                    name
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.articlesTemplateAdd) {
                    setModalAddIsOpen(false);
                    setSelectedTemplate(null);
                    queryArticlesTemplates();
                }
            }
        }
    );

    const [mutationArticlesTemplateRemove, { loading: loadingArticlesTemplateRemove }] = useMutation(
        gql`
            mutation articlesTemplateRemove($id: ID!) {
                articlesTemplateRemove(id: $id) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.articlesTemplateRemove) {
                    setModalRemoveIsOpen(false);
                    queryArticlesTemplates();
                }
            }
        }
    );

    useEffect(() => {
        queryArticlesTemplates();
    }, []);

    return (
        <>
            <Block color="white" pb={28}>
                <Block row center pl={20} pt={10} pb={10}>
                    <Text size={18} height={32}>Articles templates</Text>
                </Block>
                <Block width="100%" height={1} color="greyAlmostWhite" />
                <Block>
                    <Table
                        type={2}
                        loading={loading}
                        columns={[
                            { label: "Name", key: "name" }
                        ]}
                        rows={articlesTemplates.map((template) => ({ ...template, actions: <HasScope roles={["role.developer"]}><ButtonAction icon={<SvgIconTrash />} onClick={() => setModalRemoveIsOpen(template.id)} /></HasScope> }))}
                    />
                </Block>
                <Block row center mt={28} ml={20}>
                    <HasScope roles={["role.developer"]}><Button text="Add article template" onClick={() => setModalAddIsOpen(true)} /></HasScope>
                </Block>
            </Block>
            <ModalThatSlides
                width={"980px"}
                title="Select Articles Templates"
                isOpen={modalAddIsOpen}
                onRequestClose={() => { setModalAddIsOpen(false); setSelectedTemplate(null); }}
            >
                <Table
                    type={1}
                    loading={loading}
                    columns={[
                        { label: "Name", key: "name" }
                    ]}
                    rows={templates.map((edge) => ({
                        ...edge.node, actions: <>
                            {(() => {
                                const selected = selectedTemplate === edge.cursor
                                return (
                                    <Block pl={8} pr={8} width={164}>
                                        <Block pointer color={selected ? "greyDarker" : "greyAlmostWhite"} width="100%" height={44} center row space="between" pl={16} pr={16} onClick={() => setSelectedTemplate(edge.cursor)}>
                                            <Text size={14} height={28} color={selected ? "white" : "greyLight"}>Selected</Text>
                                            {selected && < SvgIconCheck />}
                                        </Block>
                                    </Block>
                                );
                            })()}
                        </>
                    }))}
                />
                <Block pt={24}>
                    <Button text="Submit" loading={loadingArticlesTemplateAdd} onClick={() => mutationArticlesTemplateAdd({ variables: { id: selectedTemplate } })} />
                </Block>
            </ModalThatSlides>
            <Modal
                width={280}
                title="Remove Template"
                isOpen={!!modalRemoveIsOpen}
                onRequestClose={() => setModalRemoveIsOpen(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to remove this article template?</Text>
                <Text size={12} height={14} mt={8}>This action it’s irreversible, it will erase all the data from this article template.</Text>
                <Block mt={32}>
                    <Button text="Delete" loading={loadingArticlesTemplateRemove} onClick={() => mutationArticlesTemplateRemove({ variables: { id: modalRemoveIsOpen } })} />
                </Block>
            </Modal>
        </>
    );
}

export default ArticlesTemplates;