import * as React from "react"

function IconSettings({ color = "#5555FF", ...props }) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M12.524 4.732l-1.12-1.121c-.591.306-2.172.52-2.567-1.065H7.253"
                stroke={color}
            />
            <path
                d="M3.293 4.732l1.12-1.121c.592.306 2.172.52 2.568-1.065h1.583M11.172 12.622l1.12-1.121c-.306-.593-.52-2.176 1.063-2.572V7.344"
                stroke={color}
            />
            <path
                d="M11.172 3.379l1.12 1.121c-.306.593-.52 2.176 1.063 2.572v1.585M4.646 12.622l-1.12-1.121c.305-.593.52-2.176-1.064-2.572V7.344"
                stroke={color}
            />
            <path
                d="M4.646 3.379L3.526 4.5c.305.593.52 2.176-1.064 2.572v1.585M3.293 11.268l1.12 1.121c.592-.305 2.172-.52 2.568 1.065h1.583"
                stroke={color}
            />
            <path
                d="M12.524 11.268l-1.12 1.121c-.591-.305-2.172-.52-2.567 1.065H7.253"
                stroke={color}
            />
            <ellipse cx={7.908} cy={8} rx={1.997} ry={2} stroke={color} />
        </svg>
    )
}

export default IconSettings
