import * as React from "react"

function IconUpload({ color = "#35383B", ...props }) {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect x={0.5} y={0.5} width={19} height={19} rx={9.5} stroke={color} />
            <path d="M13 10H7M10 13V7" stroke={color} strokeLinecap="square" />
        </svg>
    )
}

export default IconUpload
