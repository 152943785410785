import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ContentEditor, Header } from '../../components';
import { Block, Button, Text } from '../../components/layout';
import { SvgIconArrowLeft } from '../../svgs';
import toast from '../../utils/toast';

function CandidatureEdit() {
    const history = useHistory();
    let { candidatureId } = useParams();
    const [title, setTitle] = useState(null);
    const [result, setResult] = useState(null);

    let submitForm = null;

    const [queryCandidature, { loading }] = useLazyQuery(
        gql`
            query candidature($id: ID!) {
                candidature(id: $id) {
                    id
                    jobOffer {
                        id
                        reference
                        title
                        local
                    }
                    status
                    personal_name
                    personal_nationality
                    personal_birthdate
                    personal_gender
                    personal_qualifications
                    contacts_email
                    contacts_phone
                    contacts_location
                    interview_details
                    interview_personal_development
                    interview_salary_expectations
                    professional_experience
                    teaching_experience
                    education
                    other_courses
                    technical_skills
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.candidature) {
                    const aux = {
                        jobOffer: data.candidature.jobOffer,
                        general: {
                            personal_name: data.candidature.personal_name,
                            personal_nationality: data.candidature.personal_nationality,
                            personal_birthdate: data.candidature.personal_birthdate,
                            personal_gender: data.candidature.personal_gender,
                            personal_qualifications: data.candidature.personal_qualifications,

                            contacts_email: data.candidature.contacts_email,
                            contacts_phone: data.candidature.contacts_phone,
                            contacts_location: data.candidature.contacts_location,

                            interview_details: data.candidature.interview_details,
                            interview_personal_development: data.candidature.interview_personal_development,
                            interview_salary_expectations: data.candidature.interview_salary_expectations
                        },
                        professional_experience: {
                            professional_experience: data.candidature.professional_experience
                        },
                        teaching_experience: {
                            teaching_experience: data.candidature.teaching_experience
                        },
                        education: {
                            education: data.candidature.education
                        },
                        other_courses: {
                            other_courses: data.candidature.other_courses
                        },
                        technical_skills: {
                            technical_skills: data.candidature.technical_skills
                        }
                    };

                    setResult(aux);
                    setTitle(data.candidature.personal_name);
                }
            }
        }
    );

    const [mutationCandidatureUpdate, { loading: loadingCandidatureUpdate }] = useMutation(
        gql`
            mutation candidatureUpdate(
                $id: ID!,
                $status: CandidatureStatus
                $personal_name: String
                $personal_nationality: String
                $personal_birthdate: String
                $personal_gender: String
                $personal_qualifications: String
                $contacts_email: String
                $contacts_phone: String
                $contacts_location: String
                $interview_details: String
                $interview_personal_development: String
                $interview_salary_expectations: String
                $professional_experience: Json
                $teaching_experience: Json
                $education: Json
                $other_courses: Json
                $technical_skills: Json
            ) {
                candidatureUpdate(
                    id: $id,
                    status: $status,
                    personal_name: $personal_name,
                    personal_nationality: $personal_nationality,
                    personal_birthdate: $personal_birthdate,
                    personal_gender: $personal_gender,
                    personal_qualifications: $personal_qualifications,
                    contacts_email: $contacts_email,
                    contacts_phone: $contacts_phone,
                    contacts_location: $contacts_location,
                    interview_details: $interview_details,
                    interview_personal_development: $interview_personal_development,
                    interview_salary_expectations: $interview_salary_expectations,
                    professional_experience: $professional_experience,
                    teaching_experience: $teaching_experience,
                    education: $education,
                    other_courses: $other_courses,
                    technical_skills: $technical_skills
                ) {
                    id
                    jobOffer {
                        id
                        reference
                        title
                        local
                    }
                    status
                    personal_name
                    personal_nationality
                    personal_birthdate
                    personal_gender
                    personal_qualifications
                    contacts_email
                    contacts_phone
                    contacts_location
                    interview_details
                    interview_personal_development
                    interview_salary_expectations
                    professional_experience
                    teaching_experience
                    education
                    other_courses
                    technical_skills
                    curriculum_vitae
                    evidence_match_sent
                    evidence_match_score
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.candidatureUpdate) {
                    const aux = {
                        jobOffer: data.candidatureUpdate.jobOffer,
                        general: {
                            personal_name: data.candidatureUpdate.personal_name,
                            personal_nationality: data.candidatureUpdate.personal_nationality,
                            personal_birthdate: data.candidatureUpdate.personal_birthdate,
                            personal_gender: data.candidatureUpdate.personal_gender,
                            personal_qualifications: data.candidatureUpdate.personal_qualifications,

                            contacts_email: data.candidatureUpdate.contacts_email,
                            contacts_phone: data.candidatureUpdate.contacts_phone,
                            contacts_location: data.candidatureUpdate.contacts_location,

                            interview_details: data.candidatureUpdate.interview_details,
                            interview_personal_development: data.candidatureUpdate.interview_personal_development,
                            interview_salary_expectations: data.candidatureUpdate.interview_salary_expectations
                        },
                        professional_experience: {
                            professional_experience: data.candidatureUpdate.professional_experience
                        },
                        teaching_experience: {
                            teaching_experience: data.candidatureUpdate.teaching_experience
                        },
                        education: {
                            education: data.candidatureUpdate.education
                        },
                        other_courses: {
                            other_courses: data.candidatureUpdate.other_courses
                        },
                        technical_skills: {
                            technical_skills: data.candidatureUpdate.technical_skills
                        }
                    };

                    setResult(aux);
                    setTitle(data.candidatureUpdate.personal_name);

                    toast.success(`Saved`);
                }
            }
        }
    );

    const mutate = (values) => {
        mutationCandidatureUpdate({
            variables: {
                id: candidatureId,
                // status: XPTO,
                personal_name: values.general.personal_name,
                personal_nationality: values.general.personal_nationality,
                personal_birthdate: values.general.personal_birthdate,
                personal_gender: values.general.personal_gender,
                personal_qualifications: values.general.personal_qualifications,
                contacts_email: values.general.contacts_email,
                contacts_phone: values.general.contacts_phone,
                contacts_location: values.general.contacts_location,
                interview_details: values.general.interview_details,
                interview_personal_development: values.general.interview_personal_development,
                interview_salary_expectations: values.general.interview_salary_expectations,
                professional_experience: values.professional_experience.professional_experience,
                teaching_experience: values.teaching_experience.teaching_experience,
                education: values.education.education,
                other_courses: values.other_courses.other_courses,
                technical_skills: values.technical_skills.technical_skills,
            }
        })
    }

    useEffect(() => {
        queryCandidature({ variables: { id: candidatureId } });
    }, []);

    return (
        <>
            <Block flex height="100%">
                <Header
                    leftChildren={
                        <Block height="100%" row>
                            <Block width={52} height={52} center middle pointer onClick={() => history.push("/dashboard/candidatures")}>
                                <SvgIconArrowLeft />
                            </Block>
                            <Block width={1} height="100%" color="greyAlmostWhite" />
                            <Block width={229} height="100%" middle>
                                <Text size={18} height={32} ml={24}>{!title ? "Untitled" : title}</Text>
                            </Block>
                            <Block width={1} height="100%" color="greyAlmostWhite" />
                        </Block>
                    }
                    rightChildren={
                        <Block row>
                            <Button style={{ minWidth: 309 }} text={!!candidatureId ? "Save" : "Create"} onClick={() => { submitForm(); }} loading={loadingCandidatureUpdate} />
                        </Block>
                    }
                />
                <Block flex pl={230} pt={64} pb={64} style={{ overflow: "auto" }}>
                    {result && <ContentEditor
                        onSubmit={(values) => mutate(values)}
                        bindSubmitForm={(method) => submitForm = method}
                        values={result}
                        structure={{
                            sections: [
                                {
                                    id: "1",
                                    key: "general",
                                    title: "General data",
                                    properties: {
                                        fields: [
                                            {
                                                id: "1",
                                                key: "personal_name",
                                                title: "Name",
                                                type: "TextLine",
                                                properties: {
                                                    required: true
                                                }
                                            },
                                            {
                                                id: "2",
                                                key: "personal_nationality",
                                                title: "Nationality",
                                                type: "TextLine",
                                                properties: {
                                                    required: false
                                                }
                                            },
                                            {
                                                id: "3",
                                                key: "personal_birthdate",
                                                title: "Birthdate",
                                                type: "TextLine",
                                                properties: {
                                                    required: false,
                                                    type: "date"
                                                }
                                            },
                                            {
                                                id: "4",
                                                key: "personal_gender",
                                                title: "Gender",
                                                type: "TextLine",
                                                properties: {
                                                    required: false
                                                }
                                            },
                                            {
                                                id: "5",
                                                key: "personal_qualifications",
                                                title: "Qualifications",
                                                type: "TextLine",
                                                properties: {
                                                    required: false
                                                }
                                            },
                                            {
                                                id: "6",
                                                key: "contacts_email",
                                                title: "Email Qualifications",
                                                type: "TextLine",
                                                properties: {
                                                    required: false
                                                }
                                            },
                                            {
                                                id: "7",
                                                key: "contacts_phone",
                                                title: "Phone",
                                                type: "TextLine",
                                                properties: {
                                                    required: false
                                                }
                                            },
                                            {
                                                id: "8",
                                                key: "contacts_location",
                                                title: "Local",
                                                type: "TextLine",
                                                properties: {
                                                    required: false
                                                }
                                            },
                                            {
                                                id: "9",
                                                key: "interview_details",
                                                title: "Interview details",
                                                type: "TextEditor",
                                                properties: {
                                                    required: false
                                                }
                                            },
                                            {
                                                id: "10",
                                                key: "interview_personal_development",
                                                title: "Personal development",
                                                type: "TextEditor",
                                                properties: {
                                                    required: false
                                                }
                                            },
                                            {
                                                id: "11",
                                                key: "interview_salary_expectations",
                                                title: "Salary expectations",
                                                type: "TextLine",
                                                properties: {
                                                    required: false
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    id: "2",
                                    key: "professional_experience",
                                    title: "Professional Experience",
                                    properties: {
                                        fields: [
                                            {
                                                id: "1",
                                                key: "professional_experience",
                                                title: "Experience",
                                                type: "List",
                                                properties: {
                                                    fields: [
                                                        {
                                                            id: "1",
                                                            key: "company",
                                                            title: "Company",
                                                            type: "TextLine",
                                                            properties: {
                                                                required: false
                                                            }
                                                        },
                                                        {
                                                            id: "2",
                                                            key: "position",
                                                            title: "Position",
                                                            type: "TextLine",
                                                            properties: {
                                                                required: false
                                                            }
                                                        },
                                                        {
                                                            id: "3",
                                                            key: "duration",
                                                            title: "Duration",
                                                            type: "TextLine",
                                                            properties: {
                                                                required: false
                                                            }
                                                        },
                                                        {
                                                            id: "4",
                                                            key: "activities",
                                                            title: "Activities",
                                                            type: "TextEditor",
                                                            properties: {
                                                                required: false
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                        ]
                                    }
                                },
                                {
                                    id: "3",
                                    key: "teaching_experience",
                                    title: "Teaching experience / freelancer",
                                    properties: {
                                        fields: [
                                            {
                                                id: "1",
                                                key: "teaching_experience",
                                                title: "Experience",
                                                type: "List",
                                                properties: {
                                                    fields: [
                                                        {
                                                            id: "1",
                                                            key: "activities",
                                                            title: "Activities",
                                                            type: "TextEditor",
                                                            properties: {
                                                                required: false
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                        ]
                                    }
                                },
                                {
                                    id: "4",
                                    key: "education",
                                    title: "Education",
                                    properties: {
                                        fields: [
                                            {
                                                id: "1",
                                                key: "education",
                                                title: "Education",
                                                type: "List",
                                                properties: {
                                                    fields: [
                                                        {
                                                            id: "1",
                                                            key: "course",
                                                            title: "Course",
                                                            type: "TextLine",
                                                            properties: {
                                                                required: false
                                                            }
                                                        },
                                                        {
                                                            id: "2",
                                                            key: "institution",
                                                            title: "Institution",
                                                            type: "TextLine",
                                                            properties: {
                                                                required: false
                                                            }
                                                        },
                                                        {
                                                            id: "3",
                                                            key: "conclusion",
                                                            title: "Conclusion",
                                                            type: "TextLine",
                                                            properties: {
                                                                required: false
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                        ]
                                    }
                                },
                                {
                                    id: "5",
                                    key: "other_courses",
                                    title: "Other courses",
                                    properties: {
                                        fields: [
                                            {
                                                id: "1",
                                                key: "other_courses",
                                                title: "Other courses",
                                                type: "List",
                                                properties: {
                                                    fields: [
                                                        {
                                                            id: "1",
                                                            key: "activities",
                                                            title: "Activities",
                                                            type: "TextEditor",
                                                            properties: {
                                                                required: false
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                        ]
                                    }
                                },
                                {
                                    id: "6",
                                    key: "technical_skills",
                                    title: "Technical Skills Highlights",
                                    properties: {
                                        fields: [
                                            {
                                                id: "1",
                                                key: "technical_skills",
                                                title: "Technical Skills Highlights",
                                                type: "List",
                                                properties: {
                                                    fields: [
                                                        {
                                                            id: "1",
                                                            key: "skill",
                                                            title: "Skill",
                                                            type: "TextLine",
                                                            properties: {
                                                                required: false
                                                            }
                                                        },
                                                        {
                                                            id: "2",
                                                            key: "description",
                                                            title: "Description",
                                                            type: "TextEditor",
                                                            properties: {
                                                                required: false
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                        ]
                                    }
                                },
                            ]
                        }}
                    />}
                </Block>
            </Block>
        </>
    );
}

export default CandidatureEdit;