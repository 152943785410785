import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactLoading from 'react-loading';
import * as yup from 'yup';
import { ModalThatSlides } from '../../../../components';
import { Block, Button, Group, Text, TextInput } from '../../../../components/layout';
import { SvgIconCheck, SvgIconEmail, SvgIconLink, SvgIconDownload } from '../../../../svgs';
import { getClientToken } from '../../../../utils/client';
import colors from '../../../../utils/colors';
import toast from '../../../../utils/toast';
import validateFormSchema from '../../../../utils/validateFormSchema';

function ModalUpdateEvidenceMatch({ candidatureId, isOpen, onRequestClose }) {
    let submitForm = null;

    const [fetching, setFetching] = useState(false);

    const [isCopied, setIsCopied] = useState(false);
    const [url, setUrl] = useState(null);
    const [video, setVideo] = useState(null);
    const [sent, setSent] = useState(false);
    const [linkSent, setLinkSent] = useState(false);
    const [score, setScore] = useState(undefined);

    const [queryCandidature, { loading }] = useLazyQuery(
        gql`
            query candidature($id: ID!) {
                candidature(id: $id) {
                    id
                    evidence_match_sent
                    evidence_match_link_sent
                    evidence_match_score
                    evidence_match_url
                    video
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.candidature) {
                    setSent(data.candidature.evidence_match_sent);
                    setLinkSent(data.candidature.evidence_match_link_sent);
                    setScore(data.candidature.evidence_match_score);
                    setUrl(data.candidature.evidence_match_url);
                    setVideo(!!data.candidature.video ? data.candidature.video.url : null);
                }
            }
        }
    );

    const [mutationCandidatureUpdateScore, { loading: loadingCandidatureUpdateScore }] = useMutation(
        gql`
            mutation candidatureUpdate(
                $id: ID!,
                $evidence_match_score: String
            ) {
                candidatureUpdate(
                    id: $id,
                    evidence_match_score: $evidence_match_score
                ) {
                    id
                    evidence_match_sent
                    evidence_match_score
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.candidatureUpdate) {
                    setScore(data.candidatureUpdate.evidence_match_score);

                    toast.success(`Saved`);
                }
            }
        }
    );

    const [mutationCandidatureSendEvidenceMatchLink, { loading: loadingCandidatureSendEvidenceMatchLink }] = useMutation(
        gql`
            mutation candidatureSendEvidenceMatchLink(
                $id: ID!
            ) {
                candidatureSendEvidenceMatchLink(
                    id: $id
                )
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.candidatureSendEvidenceMatchLink) {
                    setLinkSent(true);
                }
            }
        }
    );

    useEffect(() => {
        if (candidatureId) {
            queryCandidature({ variables: { id: candidatureId } });
        } else {
            setTimeout(() => {
                setScore(undefined);
                setIsCopied(false);
                setUrl(null);
                setSent(false);
                setLinkSent(false);
                setVideo(null);
            }, 500);
        }
    }, [candidatureId]);

    const exportRequest = () => {
        setFetching(true);

        fetch(`${process.env.REACT_APP_BACKEND_HOST}/pdfs/generate/evidence-match/${candidatureId}`, {
            method: "GET",
            headers: { "Authorization": getClientToken() }
        })
            .then(res => res.blob())
            .then(blob => {
                var file = window.URL.createObjectURL(blob);
                window.open(file, "_blank");
                setFetching(false);
            })
            .catch(() => {
                setFetching(false);
            });
    }

    return (
        <ModalThatSlides
            title="Update candidature"
            width="820px"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            actions={[
                sent ? <Button key="buttonExport" color="green02" text="Export EM" loading={fetching} onClick={() => exportRequest()} /> : <></>,
                <Button key="buttonSave" color="purple02" text="Save" loading={loadingCandidatureUpdateScore} onClick={() => { submitForm(); }} />
            ]}
        >
            <Block>
                {loading && <Block center middle>
                    <ReactLoading type="spin" color={colors.purple02} height={20} width={20} />
                </Block>}
                {(!loading && score !== undefined) && <Formik
                    enableReinitialize
                    initialValues={{
                        score
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validate={values => {
                        const errors = validateFormSchema(
                            yup.object().shape({
                                score: yup.string()
                            }),
                            values
                        );

                        const errorsLength = Object.keys(errors).length;

                        if (errorsLength > 0) {
                            toast.error(`Error! You have errors in ${errorsLength} element(s)!`);
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        mutationCandidatureUpdateScore({
                            variables: {
                                id: candidatureId,
                                evidence_match_score: values.score
                            }
                        })
                    }}
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        submitForm: innerSubmitForm
                    }) => {
                        submitForm = innerSubmitForm;
                        return (
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <Block flex>
                                    {(() => {
                                        if (sent) {
                                            return (
                                                <Block pointer color="green02" pt={12} pb={12} pl={24} pr={24} row center>
                                                    <SvgIconCheck />
                                                    <Text size={12} height={28} color="white" ml={8}>Evidence Match Completed</Text>
                                                    <Block row flex right center>
                                                        <CopyToClipboard text={url} onCopy={() => setIsCopied(true)}>
                                                            {
                                                                isCopied ?
                                                                    <Text size={12} height={28} color="white" ml={8}>Copied!</Text>
                                                                    :
                                                                    <Block row center>
                                                                        <SvgIconLink />
                                                                        <Text size={12} height={28} color="white" ml={8}>Copy link</Text>
                                                                    </Block>
                                                            }
                                                        </CopyToClipboard>
                                                    </Block>
                                                </Block>
                                            );
                                        } else if (linkSent) {
                                            return (
                                                <Block pointer color="purple04" pt={12} pb={12} pl={24} pr={24} row center>
                                                    <SvgIconCheck />
                                                    <Text size={12} height={28} color="white" ml={8}>Evidence Match Sent</Text>
                                                    <Block row flex right center>
                                                        <CopyToClipboard text={url} onCopy={() => setIsCopied(true)}>
                                                            {
                                                                isCopied ?
                                                                    <Text size={12} height={28} color="white" ml={8}>Copied!</Text>
                                                                    :
                                                                    <Block row center>
                                                                        <SvgIconLink />
                                                                        <Text size={12} height={28} color="white" ml={8}>Copy link</Text>
                                                                    </Block>
                                                            }
                                                        </CopyToClipboard>
                                                    </Block>
                                                </Block>
                                            );
                                        } else {
                                            return (
                                                <Block pointer color="greyNotWhite" pt={12} pb={12} pl={24} pr={24} row center style={{ border: "1px solid #B2B6B9" }} onClick={() => mutationCandidatureSendEvidenceMatchLink({ variables: { id: candidatureId } })}>
                                                    {loadingCandidatureSendEvidenceMatchLink ?
                                                        <Block center middle height={28} width={28}>
                                                            <ReactLoading type="spin" color={colors.purple02} height={20} width={20} />
                                                        </Block>
                                                        :
                                                        <>
                                                            <SvgIconEmail />
                                                            <Text size={12} height={28} color="grey" ml={8}>Send Evidence Match</Text>
                                                        </>
                                                    }
                                                </Block>
                                            );
                                        }
                                    })()}
                                    {!!video && <Block mt={24}>
                                        <Button type="button" key="buttonExport" color="greyDark" icon={<SvgIconDownload color="white" />} text="Export candidate video" onClick={() => { window.open(video, "_blank") }} />
                                    </Block>}
                                    <Block mt={24}>
                                        <Group title="Success Score">
                                            <TextInput name="score" label="Score" type="text" value={values.score} error={errors.score} onChange={handleChange} onBlur={handleBlur} />
                                        </Group>
                                    </Block>
                                </Block>
                            </form>
                        )
                    }}
                </Formik>}
            </Block>
        </ModalThatSlides>
    );
}

export default ModalUpdateEvidenceMatch;