import * as React from "react"

function IconProducts(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M12 4v8H4V4" stroke="#5555FF" />
            <path d="M15 6l-3-2H4L1 6M10 10L6 6m4 0l-4 4" stroke="#5555FF" />
        </svg>
    )
}

export default IconProducts
