import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Modal, Table } from '../../../../components';
import { Block, Button, CheckBox, Text, TextInput } from '../../../../components/layout';
import ButtonAction from '../../../../components/Table/components/ButtonAction';
import { SvgIconEdit, SvgIconModalDelete, SvgIconTrash } from '../../../../svgs';
import HasScope from '../../../../utils/HasScope';
import validateFormSchema from '../../../../utils/validateFormSchema';

function Languages({ }) {
    const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
    const [modalUpdateIsOpen, setModalUpdateIsOpen] = useState(null);
    const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(null);
    const [languages, setLanguages] = useState([]);

    const [queryLanguages, { loading }] = useLazyQuery(
        gql`
            query {
                languages(first: 1000) {
                    edges {
                        node {
                            id
                            name
                            code
                            default
                            enabled
                        }
                        cursor
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.languages) {
                    setLanguages(data.languages.edges);
                }
            }
        }
    );

    const [mutationLanguageCreate, { loading: loadingLanguageCreate }] = useMutation(
        gql`
            mutation languageCreate($name: String!, $code: String!, $default: Boolean!, $enabled: Boolean!) {
                languageCreate(name: $name, code: $code, default: $default, enabled: $enabled) {
                    id
                    name
                    code
                    default
                    enabled
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.languageCreate) {
                    setModalCreateIsOpen(false);
                    queryLanguages();
                }
            }
        }
    );

    const [mutationLanguageUpdate, { loading: loadingLanguageUpdate }] = useMutation(
        gql`
            mutation languageUpdate($id: ID!, $name: String, $code: String, $default: Boolean, $enabled: Boolean) {
                languageUpdate(id: $id, name: $name, code: $code, default: $default, enabled: $enabled) {
                    id
                    name
                    code
                    default
                    enabled
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.languageUpdate) {
                    setModalUpdateIsOpen(false);
                    queryLanguages();
                }
            }
        }
    );

    const [mutationLanguageDelete, { loading: loadingLanguageDelete }] = useMutation(
        gql`
            mutation languageDelete($id: ID!) {
                languageDelete(id: $id) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.languageDelete) {
                    const index = languages.findIndex((edge) => edge.cursor == data.languageDelete.id);
                    if (index !== -1) {
                        languages.splice(index, 1);
                        setModalDeleteIsOpen(null);
                        setLanguages([...data]);
                    }
                }
            }
        }
    );

    useEffect(() => {
        queryLanguages();
    }, []);

    return (
        <>
            <Block color="white" pb={28}>
                <Block row center pl={20} pt={10} pb={10}>
                    <Text size={18} height={32}>Languages</Text>
                </Block>
                <Block width="100%" height={1} color="greyAlmostWhite" />
                <Block>
                    <Table
                        type={2}
                        loading={loading}
                        columns={[
                            { label: "Name", key: "name" },
                            { label: "Code", key: "code" },
                            { label: "Default", key: "default" },
                            { label: "Enabled", key: "enabled" }
                        ]}
                        rows={languages.map((edge) => ({ ...edge.node, default: edge.node.default ? "Yes" : "No", enabled: edge.node.enabled ? "Yes" : "No", actions: <><ButtonAction icon={<SvgIconEdit />} text="Edit" onClick={() => setModalUpdateIsOpen(edge.node)} /><HasScope roles={["role.developer"]}><ButtonAction icon={<SvgIconTrash />} onClick={() => setModalDeleteIsOpen(edge.cursor)} /></HasScope></> }))}
                    />
                </Block>
                <Block row center mt={28} ml={20}>
                    <HasScope roles={["role.developer"]}><Button text="Add language" onClick={() => setModalCreateIsOpen(true)} /></HasScope>
                </Block>
            </Block>
            <Modal
                title="Create Language"
                isOpen={modalCreateIsOpen}
                onRequestClose={() => setModalCreateIsOpen(false)}
            >
                <Text size={14} height={20}>Language</Text>
                <Block mt={28}>
                    <Formik
                        initialValues={{ default: false }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required(),
                                    code: yup.string().required(),
                                    default: yup.boolean().required(),
                                    enabled: yup.boolean().required()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationLanguageCreate({ variables: values });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <TextInput name="name" label="Name" type="text" onChange={handleChange} onBlur={handleBlur} value={values.name} error={errors.name} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="code" label="Code" type="text" onChange={handleChange} onBlur={handleBlur} value={values.code} error={errors.code} />
                                            </Block>
                                            <Block mt={12}>
                                                <CheckBox name="default" label="Default" onBlur={handleBlur} error={errors.default} />
                                            </Block>
                                            <Block mt={12}>
                                                <CheckBox name="enabled" label="Enabled" onBlur={handleBlur} error={errors.enabled} />
                                            </Block>
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingLanguageCreate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                title="Update Language"
                isOpen={!!modalUpdateIsOpen}
                onRequestClose={() => setModalUpdateIsOpen(null)}
            >
                <Text size={14} height={20}>Language</Text>
                <Block mt={28}>
                    <Formik
                        enableReinitialize
                        initialValues={modalUpdateIsOpen}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required(),
                                    code: yup.string().required(),
                                    default: yup.boolean().required(),
                                    enabled: yup.boolean().required()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationLanguageUpdate({ variables: values });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <TextInput name="name" label="Name" type="text" value={values.name} onChange={handleChange} onBlur={handleBlur} error={errors.name} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="code" label="Code" type="text" value={values.code} onChange={handleChange} onBlur={handleBlur} error={errors.code} />
                                            </Block>
                                            <Block mt={12}>
                                                <CheckBox name="default" label="Default" onBlur={handleBlur} error={errors.default} />
                                            </Block>
                                            <Block mt={12}>
                                                <CheckBox name="enabled" label="Enabled" onBlur={handleBlur} error={errors.enabled} />
                                            </Block>
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingLanguageUpdate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                width={280}
                title="Delete Language"
                isOpen={!!modalDeleteIsOpen}
                onRequestClose={() => setModalDeleteIsOpen(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to delete this language?</Text>
                <Text size={12} height={14} mt={8}>This action it’s irreversible, it will erase all the data from this language.</Text>
                <Block mt={32}>
                    <Button text="Delete" loading={loadingLanguageDelete} onClick={() => mutationLanguageDelete({ variables: { id: modalDeleteIsOpen } })} />
                </Block>
            </Modal>
        </>
    );
}

export default Languages;