import * as React from "react"

function IconEmail({ color = "#5D6368", ...props }) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M2 13h12V4H2v9z" stroke={color} />
            <path d="M2 4l6 4.5L14 4" stroke={color} />
        </svg>
    )
}

export default IconEmail
