import ArticleEdit from "./ArticleEdit";
import Articles from "./Articles";
import CandidatureEdit from "./CandidatureEdit";
import Candidatures from "./Candidatures";
import ContentTypeEdit from "./ContentTypeEdit";
import ContentTypes from "./ContentTypes";
import Files from "./Files";
import JobOfferEdit from "./JobOfferEdit";
import JobOffers from "./JobOffers";
import PageEdit from "./PageEdit";
import Pages from "./Pages";
import Settings from "./Settings";
import TemplateEdit from "./TemplateEdit";
import Templates from "./Templates";
import Users from "./Users";

export {
    Templates,
    TemplateEdit,
    Pages,
    PageEdit,
    ContentTypes,
    ContentTypeEdit,
    Users,
    Articles,
    ArticleEdit,
    Settings,
    JobOffers,
    JobOfferEdit,
    Candidatures,
    Files,
    CandidatureEdit
};
