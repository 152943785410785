import * as React from "react"

function IconDownload(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M11 9l-3 2-3-2M8 10.5V2M3 13.75h9.5" stroke="#fff" />
        </svg>
    )
}

export default IconDownload
