import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Header, Modal, Stats, Table } from '../../components';
import { FilterSelect, Search } from '../../components/Header/components';
import { Block, Button, Text } from '../../components/layout';
import ButtonAction from '../../components/Table/components/ButtonAction';
import { SvgIconCvDownload, SvgIconEdit, SvgIconEvidenceMatch, SvgIconModalDelete, SvgIconTrash } from '../../svgs';
import { store } from '../../utils/store';
import ModalEvidenceMatch from './components/ModalEvidenceMatch';
import ModalUpdate from './components/ModalUpdate';

function Candidatures() {
    const { language } = useContext(store);
    const paginate = useRef(false);
    const [search, setSearch] = useState("");
    const [jobOffer, setJobOffer] = useState(null);
    const [status, setStatus] = useState("");
    const [location, setLocation] = useState("");
    const [qualifications, setQualifications] = useState("");

    const [candidatureId, setCandidatureId] = useState(null);
    const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
    const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(null);
    const [modalEvidenceMatchData, setModalEvidenceMatchData] = useState(null);

    const [stats, setStats] = useState({ enabled: 0, disabled: 0 });
    const [result, setResult] = useState({ edges: [], pageInfo: { hasNextPage: false }, totalCount: 0 });
    const [query, { loading }] = useLazyQuery(
        gql`
            query ($first: Int, $after: String, $search: String, $status: CandidatureStatus, $jobOffer: ID, $location: String, $qualifications: String) {
                candidatures(first: $first, after: $after, search: $search, status: $status, jobOffer: $jobOffer, location: $location, qualifications: $qualifications) {
                    edges {
                        node {
                            id
                            status
                            personal_name
                            contacts_location
                            jobOffer {
                                id
                                title
                                local
                            }
                            curriculum_vitae
                        }
                        cursor
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                    totalCount
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.candidatures) {
                    setResult({
                        edges: paginate.current ? [...result.edges, ...data.candidatures.edges] : data.candidatures.edges,
                        pageInfo: data.candidatures.pageInfo,
                        totalCount: data.candidatures.totalCount
                    });
                    paginate.current = false;
                }
            }
        }
    );

    const [queryStats] = useLazyQuery(
        gql`
            query {
                candidaturesStats {
                    candidature
                    interview
                    evidenceMatch
                    rejected
                    contracted
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                setStats(data.candidaturesStats)
            }
        }
    );

    const [mutationJobOfferDelete, { loading: loadingJobOfferDelete }] = useMutation(
        gql`
            mutation candidatureDelete($id: ID!) {
                candidatureDelete(id: $id) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.candidatureDelete) {
                    queryStats();

                    let index = -1;
                    if (modalDeleteIsOpen.includes("sub")) {
                        const ids = modalDeleteIsOpen.split("sub");
                        index = result.edges.findIndex((edge) => edge.cursor == ids[0]);
                        if (index !== -1) {
                            const innerIndex = result.edges[index].node.subJobOffers.findIndex((sub) => sub.id == ids[1]);
                            if (innerIndex !== -1) {
                                result.edges[index].node.subJobOffers.splice(innerIndex, 1);
                                setModalDeleteIsOpen(null);
                                setResult([...data]);
                            }
                        }
                    } else {
                        index = result.edges.findIndex((edge) => edge.cursor == data.candidatureDelete.id);
                        if (index !== -1) {
                            result.edges.splice(index, 1);
                            setModalDeleteIsOpen(null);
                            setResult([...data]);
                        }
                    }
                }
            }
        }
    );

    const requestData = (params = { next: false }) => {
        const { first, after, next } = params;

        if (next) {
            paginate.current = true;
        }

        query({
            variables: {
                first: first || 20,
                after: after || undefined,
                search: search !== "" ? search : undefined,
                status: status && status !== "" ? status : undefined,
                jobOffer: jobOffer ? jobOffer.value : undefined,
                location: location && location !== "" ? location : undefined,
                qualifications: qualifications && qualifications !== "" ? qualifications : undefined
            }
        });
    }

    const onUpdateData = (candidature) => {
        const index = result.edges.findIndex((edge) => edge.cursor === candidatureId);

        if (index !== -1) {
            result.edges[index].node.status = candidature.status;
            result.edges[index].node.personal_name = candidature.personal_name;
            setResult({ ...result });

        }

        queryStats();
    }

    useEffect(() => {
        queryStats();
        requestData();
    }, [language]);

    useEffect(() => {
        requestData();
    }, [search, status, jobOffer, location, qualifications]);

    useEffect(() => {
        setModalCreateIsOpen(!!candidatureId);
    }, [candidatureId]);

    return (
        <>
            <Block flex height="100%">
                <Header
                    leftChildren={
                        <Block row>
                            <Search onChange={(e) => setSearch(e.target.value)} />
                            <FilterSelect
                                searchable
                                width="180px"
                                label="Job offer"
                                searchQuery={gql`
                                    query($value: String!) {
                                        jobOffersFilter(value: $value) {
                                            label
                                            value
                                        }
                                    }
                                `}
                                value={jobOffer}
                                onChange={setJobOffer}
                            />
                            <Search width="180px" placeholder="Local" onChange={(e) => setLocation(e.target.value)} />
                            <Search width="180px" placeholder="Qualifications" onChange={(e) => setQualifications(e.target.value)} />
                            <FilterSelect
                                width="180px"
                                label="Status"
                                options={[
                                    { value: "CANDIDATURE", label: "Candidature" },
                                    { value: "IN_ANALYSIS", label: "In Analysis" },
                                    { value: "INTERVIEW", label: "Interview" },
                                    { value: "EVIDENCE_MATCH", label: "Evidence Match" },
                                    { value: "REJECTED", label: "Rejected" },
                                    { value: "CONTRACTED", label: "Contracted" }
                                ]}
                                value={status}
                                onChange={setStatus}
                            />
                        </Block>
                    }
                    rightChildren={
                        <Block row>
                            {/* <Button style={{ minWidth: 309 }} icon={<SvgIconCandidatures color="white" />} text="Add Candidature" onClick={() => history.push(`/editor/candidature`)} /> */}
                        </Block>
                    }
                />
                <Block row pl={20} pr={20} pt={32}>
                    <Block flex><Stats label="Candidature" value={stats.candidature} /></Block>
                    <Block color="grey" width={1} height={52} />
                    <Block flex><Stats label="Interview" value={stats.interview} /></Block>
                    <Block color="grey" width={1} height={52} />
                    <Block flex><Stats label="Evidence Match" value={stats.evidenceMatch} /></Block>
                    <Block color="grey" width={1} height={52} />
                    <Block flex><Stats label="Rejected" value={stats.rejected} /></Block>
                    <Block color="grey" width={1} height={52} />
                    <Block flex><Stats label="Contracted" value={stats.contracted} /></Block>
                </Block>
                <Block height="100%" mr={20} ml={20} style={{ overflow: "hidden" }}>
                    <Table
                        loading={loading}
                        columns={[
                            { label: "Name", key: "personal_name" },
                            { label: "Job offer", key: "title" },
                            { label: "Local", key: "local" },
                            { label: "Status", key: "status" }
                        ]}
                        rows={result.edges.map((edge) => ({
                            ...edge.node,
                            title: edge.node.jobOffer.title,
                            local: edge.node.jobOffer.local,
                            status: <Block row center>
                                {edge.node.status === "IN_ANALYSIS" && <Block width={16} height={16} br={8} mr={4} color="purple02" />}
                                {(edge.node.status[0] + edge.node.status.toLowerCase().substring(1)).replace("_", " ")}
                            </Block>,
                            actions: <>
                                <ButtonAction icon={<SvgIconCvDownload />} text="Candidature CV" onClick={() => window.open(edge.node.curriculum_vitae, "_blank")} />
                                <ButtonAction icon={<SvgIconEvidenceMatch />} text="EM" onClick={() => setModalEvidenceMatchData(edge.cursor)} />
                                <ButtonAction icon={<SvgIconEdit />} text="Edit" onClick={() => setCandidatureId(edge.cursor)} />
                                <ButtonAction icon={<SvgIconTrash />} onClick={() => setModalDeleteIsOpen(edge.cursor)} />
                            </>
                        }))}
                        next={() => requestData({ next: true, after: result.pageInfo.endCursor })}
                        hasMore={result.pageInfo.hasNextPage}
                    />
                </Block>
            </Block>
            <Modal
                width={300}
                title="Delete Candidature"
                isOpen={!!modalDeleteIsOpen}
                onRequestClose={() => setModalDeleteIsOpen(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to delete this candidature?</Text>
                <Text size={12} height={14} mt={8}>This action it’s irreversible, it will erase all the data from this candidature.</Text>
                <Block mt={32}>
                    <Button text="Delete" loading={loadingJobOfferDelete} onClick={() => {
                        if (modalDeleteIsOpen.includes("sub")) {
                            const id = modalDeleteIsOpen.split("sub")[1];
                            mutationJobOfferDelete({ variables: { id } });
                        } else {
                            mutationJobOfferDelete({ variables: { id: modalDeleteIsOpen } });
                        }
                    }} />
                </Block>
            </Modal>
            <ModalUpdate candidatureId={candidatureId} isOpen={modalCreateIsOpen} onRequestClose={() => setCandidatureId(null)} onUpdateData={onUpdateData} />
            <ModalEvidenceMatch candidatureId={modalEvidenceMatchData} isOpen={!!modalEvidenceMatchData} onRequestClose={() => setModalEvidenceMatchData(null)} />
        </>
    );
}

export default Candidatures;