import { FieldArray } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { SvgIconDown, SvgIconMoveDown, SvgIconMoveUp, SvgIconSettings, SvgIconTextLine, SvgIconTrash, SvgIconUp } from '../../../../../svgs';
import colors from '../../../../../utils/colors';
import { Block, Text } from '../../../../layout';

function ListItem({
    index,
    path,
    value = {},
    errors = {},
    properties,
    getElement,
    onDelete,
    onMoveDown,
    onMoveUp,
    onChange,
    onBlur
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [isChildrenOpen, setIsChildrenOpen] = useState(false);

    const node = useRef();
    const handleClick = e => {
        if (node.current.contains(e.target)) {
            return;
        }
        setIsOpen(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);
    return (
        <Wrapper>
            <Block height={44} middle color="greyNotWhite" style={{ position: "relative", border: `1px solid ${colors.greyLightest}` }}>
                <Text size={14} height={20} ml={24}>List item {index + 1}</Text>
                <Settings row>
                    <Block pointer onClick={() => setIsOpen(true)}>
                        <SvgIconSettings color={colors.greyDark} />
                    </Block>
                    <Block pointer ml={4} onClick={() => setIsChildrenOpen(!isChildrenOpen)}>
                        {isChildrenOpen ? <SvgIconUp /> : <SvgIconDown />}
                    </Block>
                </Settings>
            </Block>
            <OptionsList ref={node} show={isOpen.toString()} color="white" width={180}>
                <OptionsListItem row pt={16} pb={16} pl={24} pr={24} center onClick={() => { onDelete && onDelete(); setIsOpen(false); }}>
                    <SvgIconTrash />
                    <Text size={14} height={20} ml={10}>Delete</Text>
                </OptionsListItem>
                <Block width="100%" height={1} color="greyAlmostWhite" />
                <OptionsListItem row pt={16} pb={16} pl={24} pr={24} center onClick={() => { onMoveUp && onMoveUp(); setIsOpen(false); }}>
                    <SvgIconMoveUp />
                    <Text size={14} height={20} ml={10}>Move up</Text>
                </OptionsListItem>
                <Block width="100%" height={1} color="greyAlmostWhite" />
                <OptionsListItem row pt={16} pb={16} pl={24} pr={24} center onClick={() => { onMoveDown && onMoveDown(); setIsOpen(false); }}>
                    <SvgIconMoveDown />
                    <Text size={14} height={20} ml={10}>Move down</Text>
                </OptionsListItem>
            </OptionsList>
            <Children open={isChildrenOpen}>
                {properties.fields && <FieldArray
                    name={`${path}`}
                    render={() => {
                        return (
                            properties.fields.map((field, fieldIndex) => {
                                const Element = getElement(field.type);
                                return (
                                    <Block key={`${index}${field.id}`}>
                                        <Item mt={0}>
                                            <Element
                                                type={field.type}
                                                path={`${path}.${field.key}`}
                                                title={field.title}
                                                value={value[field.key]}
                                                errors={errors}
                                                fieldKey={field.key}
                                                properties={field.properties}
                                                getElement={getElement}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                            />
                                        </Item>
                                        {(fieldIndex < properties.fields.length - 1) && <Block pl={24} pr={24}>
                                            <Block width="100%" height={1} color="greyLightest" mt={4} />
                                        </Block>}
                                    </Block>
                                );
                            })
                        );
                    }}
                />}
            </Children>
        </Wrapper >
    );
}

const Wrapper = styled(Block)`
    position: relative;
`;

const Settings = styled(Block)`
    position: absolute;
    top: 14px;
    right: 14px;
`;

const OptionsList = styled(Block)`
    position: absolute;
    top: 0;
    right: -210px;
    opacity: ${({ show }) => show === "true" ? 1 : 0};
    visibility: ${({ show }) => show === "true" ? "visible" : "hidden"};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
`;

const OptionsListItem = styled(Block)`
    cursor: pointer;
`;

const Item = styled(Block)`
    
`;


const Children = styled(Block)`
    ${props => {
        if (props.open) {
            return css`
                border-left: 1px solid ${colors.greyLightest};
                border-right: 1px solid ${colors.greyLightest};
                border-bottom: 1px solid ${colors.greyLightest};
            `;
        }
    }}
    overflow: ${({ open }) => open ? "visible" : "hidden"};
    height: ${({ open }) => open ? "auto" : "0px"};
`;

export default React.memo(ListItem);