import SvgIconAngleDown from './IconAngleDown';
import SvgIconArrowLeft from './IconArrowLeft';
import SvgIconButton from './IconButton';
import SvgIconCandidatures from './IconCandidatures';
import SvgIconCaseStudies from './IconCaseStudies';
import SvgIconCheck from './IconCheck';
import SvgIconCirclePlus from './IconCirclePlus';
import SvgIconClients from './IconClients';
import SvgIconClose from './IconClose';
import SvgIconContentTypes from './IconContentTypes';
import SvgIconCvDownload from './IconCvDownload';
import SvgIconDown from './IconDown';
import SvgIconDownload from './IconDownload';
import SvgIconEdit from './IconEdit';
import SvgIconEmail from './IconEmail';
import SvgIconEvidenceMatch from './IconEvidenceMatch';
import SvgIconFile from './IconFile';
import SvgIconImage from './IconImage';
import SvgIconImages from './IconImages';
import SvgIconJobOffers from './IconJobOffers';
import SvgIconLink from './IconLink';
import SvgIconList from './IconList';
import SvgIconLogout from './IconLogout';
import SvgIconMetatags from './IconMetatags';
import SvgIconModalDelete from './IconModalDelete';
import SvgIconMoveDown from './IconMoveDown';
import SvgIconMoveUp from './IconMoveUp';
import SvgIconNews from './IconNews';
import SvgIconPages from './IconPages';
import SvgIconPlus from './IconPlus';
import SvgIconProducts from './IconProducts';
import SvgIconSearch from './IconSearch';
import SvgIconSection from './IconSection';
import SvgIconSettings from './IconSettings';
import SvgIconTemplates from './IconTemplates';
import SvgIconTextEditor from './IconTextEditor';
import SvgIconTextLine from './IconTextLine';
import SvgIconTextMultiLine from './IconTextMultiLine';
import SvgIconTrash from './IconTrash';
import SvgIconUp from './IconUp';
import SvgIconUpload from './IconUpload';
import SvgIconUsers from './IconUsers';
import SvgLogo from './Logo';
import SvgLogoWhite from './LogoWhite';

export {
    SvgLogo,
    SvgLogoWhite,
    SvgIconPages,
    SvgIconLogout,
    SvgIconClients,
    SvgIconContentTypes,
    SvgIconImages,
    SvgIconNews,
    SvgIconProducts,
    SvgIconSettings,
    SvgIconTemplates,
    SvgIconUsers,
    SvgIconPlus,
    SvgIconTextLine,
    SvgIconUp,
    SvgIconClose,
    SvgIconDown,
    SvgIconSearch,
    SvgIconEdit,
    SvgIconTrash,
    SvgIconMetatags,
    SvgIconCheck,
    SvgIconModalDelete,
    SvgIconArrowLeft,
    SvgIconCaseStudies,
    SvgIconFile,
    SvgIconCirclePlus,
    SvgIconMoveUp,
    SvgIconMoveDown,
    SvgIconTextMultiLine,
    SvgIconTextEditor,
    SvgIconImage,
    SvgIconButton,
    SvgIconList,
    SvgIconSection,
    SvgIconUpload,
    SvgIconJobOffers,
    SvgIconCandidatures,
    SvgIconAngleDown,
    SvgIconCvDownload,
    SvgIconEvidenceMatch,
    SvgIconEmail,
    SvgIconLink,
    SvgIconDownload
};
