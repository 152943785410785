import * as React from "react"

function IconNews(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M10 12V3H2v9M10 12a2 2 0 104 0V9h-4" stroke="#5555FF" />
            <path d="M2 12a2 2 0 002 2h8" stroke="#5555FF" />
        </svg>
    )
}

export default IconNews
