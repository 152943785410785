import * as React from "react"

function IconLink(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M10.778 6.95l.707-.707a2 2 0 10-2.828-2.829L5.83 6.243A2 2 0 108.657 9.07l.707-.707"
                stroke="#fff"
            />
            <rect
                x={8.657}
                y={6.243}
                width={4}
                height={8}
                rx={2}
                transform="rotate(45 8.657 6.243)"
                stroke="#fff"
            />
        </svg>
    )
}

export default IconLink
