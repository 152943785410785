import * as React from "react"

function IconJobOffers({ color = "#55F", ...props }) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M11 12v2H2V3h9v3.667" stroke={color} />
            <circle cx={10.5} cy={9.5} r={2.5} stroke={color} />
            <path d="M12 11l3 3" stroke={color} />
        </svg>
    )
}

export default IconJobOffers
