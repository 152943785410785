import { FieldArray, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import toast from '../../../utils/toast';
import validateFormSchema from '../../../utils/validateFormSchema';
import { Block } from '../../layout';
import { Element, List, Section } from './components';

function ContentEditor({ values = {}, structure = { sections: [] }, onSubmit, bindSubmitForm }) {

    const getElement = (type) => {
        switch (type) {
            case "List": return List;
            default: return Element;
        }
    }

    return (
        <Block width={580}>
            <Formik
                initialValues={values}
                validateOnChange={false}
                validateOnBlur={false}
                validate={values => {
                    const buildValidation = (fields) => {
                        const shape = {};

                        for (let i = 0; i < fields.length; i++) {
                            const field = fields[i];
                            switch (field.type) {
                                case "List":
                                    shape[field.key] = yup.array().of(yup.object(buildValidation(field.properties.fields)));
                                    break;
                                case "TextLine":
                                case "TextMultiLine":
                                case "TextEditor":
                                    shape[field.key] = yup.string();
                                    break;
                                case "File":
                                    shape[field.key] = yup.object().nullable();
                                    break;
                                case "Button":
                                    const innerShape = {
                                        title: yup.string(),
                                        url: yup.string().url()
                                    };

                                    if (field.properties) {
                                        if (field.properties.required) {
                                            innerShape.title = innerShape.title.required('This field is required');
                                            innerShape.url = innerShape.url.required('This field is required');
                                        }
                                    }

                                    shape[field.key] = yup.object().shape(innerShape);
                                    break;
                                default:
                                    shape[field.key] = yup.string();
                                    break;
                            }

                            if (field.properties && !["Button"].includes(field.type)) {
                                if (field.properties.required) {
                                    shape[field.key] = shape[field.key].required('This field is required');
                                    if (field.type !== "File") {
                                        shape[field.key] = shape[field.key].transform((function (value, originalValue) { if (value === null || value === undefined) { return "" } return originalValue; }))
                                    }
                                } else {
                                    shape[field.key] = shape[field.key].nullable();
                                }
                            }
                        }

                        console.log(shape)

                        return shape;
                    }

                    const shape = {};

                    for (let i = 0; i < structure.sections.length; i++) {
                        const section = structure.sections[i];
                        shape[section.key] = yup.object(buildValidation(section.properties.fields));
                    }

                    const schema = yup.object().shape(shape);
                    const errors = validateFormSchema(schema, values);
                    const errorsLength = Object.keys(errors).length;

                    if (errorsLength > 0) {
                        toast.error(`Error! You have errors in ${errorsLength} element(s)!`)
                    }

                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);

                    const formatted = {};

                    for (let i = 0; i < structure.sections.length; i++) {
                        const section = structure.sections[i];
                        if (!values[section.key]) {
                            formatted[section.key] = {};
                        } else {
                            formatted[section.key] = values[section.key];
                        }
                    }

                    onSubmit && onSubmit(formatted);
                }}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    submitForm
                }) => {
                    bindSubmitForm && bindSubmitForm(submitForm);
                    return (
                        <form onSubmit={handleSubmit}>
                            <FieldArray
                                name="sections"
                                render={() => (
                                    structure.sections.map((section) => {
                                        return (
                                            <Block key={section.id}>
                                                <Section
                                                    title={section.title}
                                                >
                                                    <FieldArray
                                                        name={`${section.key}`}
                                                        render={() => {
                                                            if (section.properties) {
                                                                return (
                                                                    section.properties.fields.map((field, index) => {
                                                                        const Element = getElement(field.type);
                                                                        const first = index === 0;
                                                                        return (
                                                                            <Block key={field.id} mt={first ? 20 : 0} mb={52}>
                                                                                <Element
                                                                                    type={field.type}
                                                                                    path={`${section.key}.${field.key}`}
                                                                                    title={field.title}
                                                                                    fieldKey={field.key}
                                                                                    value={values[section.key] && values[section.key][field.key]}
                                                                                    errors={errors}
                                                                                    properties={field.properties}
                                                                                    getElement={getElement}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                />
                                                                            </Block>
                                                                        );
                                                                    })
                                                                );
                                                            }

                                                        }}
                                                    />
                                                </Section>
                                            </Block>
                                        )
                                    })
                                )}
                            />
                        </form>
                    )
                }}
            </Formik>
        </Block >
    );
}

export default ContentEditor;