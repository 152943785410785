import * as React from "react"

function LogoWhite(props) {
    return (
        <svg
            width={93}
            height={24}
            viewBox="0 0 93 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M11.316 7.278v4.03H6.538v12.226H2.345V11.31H0V7.278H11.316zM18.482 11.309v12.225H14.29V7.278h4.193v4.03zM30.9 6.819c1.775 0 3.247.599 4.408 1.79 1.161 1.19 1.738 2.84 1.738 4.94v9.985h-4.193v-9.467c0-1.087-.296-1.916-.88-2.485-.585-.577-1.369-.866-2.345-.866-1.087 0-1.952.333-2.603 1.006-.651.673-.976 1.68-.976 3.025v8.78h-4.194V7.276h4.194v1.82c1.02-1.516 2.64-2.278 4.851-2.278zM52.319.769h4.193v22.765H52.32v-1.916c-1.235 1.583-2.995 2.374-5.266 2.374-2.19 0-4.06-.828-5.613-2.485-1.554-1.656-2.323-3.69-2.323-6.101 0-2.412.777-4.438 2.323-6.102 1.553-1.657 3.416-2.485 5.613-2.485 2.278 0 4.03.791 5.266 2.374V.769zm-7.721 17.935c.858.858 1.937 1.287 3.239 1.287 1.302 0 2.374-.429 3.217-1.287.843-.858 1.272-1.96 1.272-3.298 0-1.347-.421-2.448-1.272-3.299-.843-.858-1.915-1.287-3.217-1.287s-2.382.43-3.24 1.287c-.857.858-1.286 1.96-1.286 3.299 0 1.346.429 2.448 1.287 3.298zM68.768 6.819c4.97 0 6.338 4.179 6.146 6.73l-.792 9.985h-4.127l.725-9.467c0-1.087-.296-1.915-.88-2.485-.584-.577-1.368-.865-2.344-.865-1.088 0-3.247.547-3.58 4.03l-.525 6.864a2.076 2.076 0 01-4.142-.303L60.721.762h4.26l-.614 8.017c.717-.85 2.19-1.96 4.4-1.96zM89.602 7.278h1.22c.865 0 1.546.74 1.48 1.597L91.17 23.541h-4.194l.148-1.915C86.104 23.142 84.344 24 82.132 24c-1.775 0-3.247-.599-4.408-1.79-1.161-1.19-1.738-2.84-1.738-4.94l.784-9.963 4.142-.022-.733 9.467c0 1.087.296 1.916.88 2.485.585.577 1.369.865 2.345.865 1.087 0 1.953-.332 2.603-1.006.651-.672 1.48-2.093 1.605-3.675l.518-6.76a1.468 1.468 0 011.472-1.383zM6.523 7.278H2.39V5.776A5.784 5.784 0 018.165 0h4.74v4.134h-4.74c-.902 0-1.642.74-1.642 1.642v1.502zM16.382 4.2a2.1 2.1 0 100-4.2 2.1 2.1 0 000 4.2z"
                fill="#fff"
            />
            <path
                d="M90.223 10.776a2.093 2.093 0 100-4.186 2.093 2.093 0 000 4.186z"
                fill="#E31C46"
            />
            <path
                d="M61.327 23.52a2.07 2.07 0 100-4.143 2.07 2.07 0 000 4.142z"
                fill="#4CD6C5"
            />
        </svg>
    )
}

export default LogoWhite
