import React from "react";
import ReactLoading from 'react-loading';
import { Block } from "..";
import colors from "../../../utils/colors";
import Text from "../Text";

export default function Button(
  {
    icon,
    text,
    color,
    textColor,
    shadow,
    loading,
    style,
    children,
    ...props
  }
) {

  const buttonStyles = [
    styles.button,
    color && styles[color], // predefined styles colors for backgroundColor
    color && !styles[color] && { backgroundColor: color }, // custom backgroundColor
    style
  ];

  return (
    <button
      style={buttonStyles.reduce((prev = {}, curr) => {
        if (!curr) return prev;
        return { ...prev, ...curr };
      })}
      disabled={loading}
      {...props}
    >
      {loading ? <ReactLoading type="spin" color={textColor || "white"} height={20} width={20} /> : children}
      {(!loading && (!!text || !!icon)) &&
        <Block row>
          {!!icon && <Block middle mr={!!text ? 8 : 0}>
            {icon}
          </Block>}
          {!!text && <Text color={textColor || "white"} size={14} height={28}>{text}</Text>}
        </Block>
      }
    </button>
  );
}

const backgrounds = Object.keys(colors).reduce((prev, current) => {
  return {
    ...prev,
    [current]: { backgroundColor: colors[current] }
  }
}, {});

const styles = {
  button: {
    cursor: "pointer",
    fontFamily: "Medium",
    border: 0,
    height: 52,
    backgroundColor: colors.purple02,
    padding: "12px 16px",
    minWidth: 176,
    width: "fit-content",
    textAlign: "left"
  },
  ...backgrounds
};