import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Header, Modal, Stats, Table } from '../../components';
import { FilterSelect, Search } from '../../components/Header/components';
import { Block, Button, Text } from '../../components/layout';
import ButtonAction from '../../components/Table/components/ButtonAction';
import { SvgIconEdit, SvgIconJobOffers, SvgIconModalDelete, SvgIconTrash } from '../../svgs';
import { store } from '../../utils/store';
import ModalCreate from './components/ModalCreate';
import ModalUpdate from './components/ModalUpdate';

function JobOffers() {
    const { language } = useContext(store);
    const paginate = useRef(false);
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("");

    const [jobOfferId, setJobOfferId] = useState(null);
    const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
    const [modalUpdateIsOpen, setModalUpdateIsOpen] = useState(false);
    const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(null);

    const [stats, setStats] = useState({ enabled: 0, disabled: 0 });
    const [result, setResult] = useState({ edges: [], pageInfo: { hasNextPage: false }, totalCount: 0 });
    const [query, { loading }] = useLazyQuery(
        gql`
            query ($first: Int, $after: String, $search: String, $status: JobOfferStatus) {
                jobOffers(first: $first, after: $after, search: $search, status: $status) {
                    edges {
                        node {
                            id
                            reference
                            status
                            title
                            local
                            description
                            owner {
                                id
                                name
                            }
                        }
                        cursor
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                    totalCount
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.jobOffers) {
                    setResult({
                        edges: paginate.current ? [...result.edges, ...data.jobOffers.edges] : data.jobOffers.edges,
                        pageInfo: data.jobOffers.pageInfo,
                        totalCount: data.jobOffers.totalCount
                    });
                    paginate.current = false;
                }
            }
        }
    );

    const [queryStats] = useLazyQuery(
        gql`
            query {
                jobOffersStats {
                    enabled
                    disabled
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                setStats(data.jobOffersStats)
            }
        }
    );

    const [mutationJobOfferDelete, { loading: loadingJobOfferDelete }] = useMutation(
        gql`
            mutation jobOfferDelete($id: ID!) {
                jobOfferDelete(id: $id) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.jobOfferDelete) {
                    queryStats();

                    let index = -1;
                    if (modalDeleteIsOpen.includes("sub")) {
                        const ids = modalDeleteIsOpen.split("sub");
                        index = result.edges.findIndex((edge) => edge.cursor == ids[0]);
                        if (index !== -1) {
                            const innerIndex = result.edges[index].node.subJobOffers.findIndex((sub) => sub.id == ids[1]);
                            if (innerIndex !== -1) {
                                result.edges[index].node.subJobOffers.splice(innerIndex, 1);
                                setModalDeleteIsOpen(null);
                                setResult([...data]);
                            }
                        }
                    } else {
                        index = result.edges.findIndex((edge) => edge.cursor == data.jobOfferDelete.id);
                        if (index !== -1) {
                            result.edges.splice(index, 1);
                            setModalDeleteIsOpen(null);
                            setResult([...data]);
                        }
                    }
                }
            }
        }
    );

    const requestData = (params = { next: false }) => {
        const { first, after, next } = params;

        if (next) {
            paginate.current = true;
        }

        query({
            variables: {
                first: first || 20,
                after: after || undefined,
                search: search !== "" ? search : undefined,
                status: status && status !== "" ? status : undefined
            }
        });
    }

    const onUpdateData = (jobOffer) => {
        const index = result.edges.findIndex((edge) => edge.cursor === jobOfferId);

        if (index !== -1) {
            result.edges[index].node.reference = jobOffer.reference;
            result.edges[index].node.status = jobOffer.status;
            result.edges[index].node.title = jobOffer.title;
            result.edges[index].node.local = jobOffer.local;
            result.edges[index].node.description = jobOffer.description;
            result.edges[index].node.owner = jobOffer.owner;
            setResult({ ...result });

        }

        queryStats();
    }

    const onCreateData = (jobOffer) => {
        result.edges.unshift({
            cursor: jobOffer.id,
            node: {
                id: jobOffer.id,
                reference: jobOffer.reference,
                status: jobOffer.status,
                title: jobOffer.title,
                local: jobOffer.local,
                description: jobOffer.description,
                owner: jobOffer.owner
            }
        });

        setResult({ ...result });

        queryStats();
    }

    useEffect(() => {
        queryStats();
        requestData();
    }, [language]);

    useEffect(() => {
        requestData();
    }, [search, status]);

    useEffect(() => {
        setModalUpdateIsOpen(!!jobOfferId);
    }, [jobOfferId]);

    return (
        <>
            <Block flex height="100%">
                <Header
                    leftChildren={
                        <Block row>
                            <Search onChange={(e) => setSearch(e.target.value)} />
                            <FilterSelect
                                width="120px"
                                label="Status"
                                options={[
                                    { value: "ENABLED", label: "Enabled" },
                                    { value: "DISABLED", label: "Disabled" }
                                ]}
                                value={status}
                                onChange={setStatus}
                            />
                        </Block>
                    }
                    rightChildren={
                        <Block row>
                            <Button style={{ minWidth: 309 }} icon={<SvgIconJobOffers color="white" />} text="Add Job Offer" onClick={() => setModalCreateIsOpen(true)} />
                        </Block>
                    }
                />
                <Block row pl={20} pt={32}>
                    <Block width={236}><Stats label="Enabled" value={stats.enabled} /></Block>
                    <Block color="grey" width={1} height={52} />
                    <Block width={236}><Stats label="Disabled" value={stats.disabled} /></Block>
                </Block>
                <Block height="100%" mr={20} ml={20} style={{ overflow: "hidden" }}>
                    <Table
                        loading={loading}
                        columns={[
                            { label: "Reference", key: "reference" },
                            { label: "Title", key: "title" },
                            { label: "Local", key: "local" },
                            { label: "Owner", key: "owner" },
                            { label: "Status", key: "status" }
                        ]}
                        rows={result.edges.map((edge) => ({ ...edge.node, owner: edge.node.owner ? edge.node.owner.name : "", status: edge.node.status[0] + edge.node.status.toLowerCase().substring(1), actions: <><ButtonAction icon={<SvgIconEdit />} text="Edit" onClick={() => setJobOfferId(edge.cursor)} /><ButtonAction icon={<SvgIconTrash />} onClick={() => setModalDeleteIsOpen(edge.cursor)} /></> }))}
                        next={() => requestData({ next: true, after: result.pageInfo.endCursor })}
                        hasMore={result.pageInfo.hasNextPage}
                    />
                </Block>
            </Block>
            <Modal
                width={300}
                title="Delete Job Offer"
                isOpen={!!modalDeleteIsOpen}
                onRequestClose={() => setModalDeleteIsOpen(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to delete this job offer?</Text>
                <Text size={12} height={14} mt={8}>This action it’s irreversible, it will erase all the data from this job offer.</Text>
                <Block mt={32}>
                    <Button text="Delete" loading={loadingJobOfferDelete} onClick={() => {
                        if (modalDeleteIsOpen.includes("sub")) {
                            const id = modalDeleteIsOpen.split("sub")[1];
                            mutationJobOfferDelete({ variables: { id } });
                        } else {
                            mutationJobOfferDelete({ variables: { id: modalDeleteIsOpen } });
                        }
                    }} />
                </Block>
            </Modal>
            <ModalUpdate jobOfferId={jobOfferId} isOpen={modalUpdateIsOpen} onRequestClose={() => setJobOfferId(null)} onUpdateData={onUpdateData} />
            <ModalCreate isOpen={modalCreateIsOpen} onRequestClose={() => setModalCreateIsOpen(false)} onCreateData={onCreateData} />
        </>
    );
}

export default JobOffers;