import * as React from "react"

function IconEvidenceMatch(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M5 13V8M9 13V9M13 13V6M2 7l3-1 4 1.5L13 4" stroke="#35383B" />
            <path d="M14 13H2V2" stroke="#35383B" />
        </svg>
    )
}

export default IconEvidenceMatch
