import { FieldArray } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { SvgIconMoveDown, SvgIconMoveUp, SvgIconPlus, SvgIconTrash } from '../../../svgs';
import colors from '../../../utils/colors';
import Block from '../Block';
import Text from '../Text';

function ListInput({
    name,
    label = "Item",
    title,
    items = [],
    value = [],
    errors = {},
    onChange,
    onBlur
}) {

    return (
        <Wrapper>
            <Text size={14} height={20} mb={16}>{title}</Text>
            <FieldArray
                name={name}
                render={arrayHelpers => {
                    if (!value || value.length === 0) {
                        return (
                            <Bar
                                width="100%"
                                height={28}
                                middle
                                center
                                onClick={() => {
                                    arrayHelpers.insert(0, {});
                                }}>
                                <Plus />
                            </Bar>
                        );
                    }

                    return (
                        value.map((itemValue, itemValueIndex) => {
                            return (
                                <div key={itemValueIndex}>
                                    <Block color="white" key={itemValueIndex} pl={24} pr={24} pb={16} pt={16} style={{ position: "relative" }}>
                                        <Text size={14} height={20} mb={16}>{label} #{itemValueIndex}</Text>
                                        <OptionsList row>
                                            <OptionsListItem row center onClick={() => { arrayHelpers.remove(itemValueIndex); }}>
                                                <SvgIconTrash />
                                            </OptionsListItem>
                                            <Block width="100%" height={1} color="greyAlmostWhite" />
                                            <OptionsListItem row center onClick={() => { arrayHelpers.move(itemValueIndex, itemValueIndex - 1); }}>
                                                <SvgIconMoveUp />
                                            </OptionsListItem>
                                            <Block width="100%" height={1} color="greyAlmostWhite" />
                                            <OptionsListItem row center onClick={() => { arrayHelpers.move(itemValueIndex, itemValueIndex + 1); }}>
                                                <SvgIconMoveDown />
                                            </OptionsListItem>
                                        </OptionsList>
                                        {items.map((item, index) => {
                                            return (
                                                <Item key={index} mb={items.length - 1 === index ? 0 : 12}>
                                                    <item.Component
                                                        name={`${name}[${itemValueIndex}].${item.name}`}
                                                        label={item.label}
                                                        value={itemValue[item.name]}
                                                        error={errors[`[${itemValueIndex}].${item.name}`]}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                    />
                                                </Item>
                                            )
                                        })}
                                    </Block>
                                    <Block mt={20} mb={value.length - 1 === itemValueIndex ? 0 : 16}>
                                        <Bar
                                            width="100%"
                                            height={28}
                                            middle
                                            center
                                            onClick={() => {
                                                arrayHelpers.insert(itemValueIndex + 1, {});
                                            }}>
                                            <Plus />
                                        </Bar>
                                    </Block>
                                </div>
                            );
                        })
                    );
                }}
            />
        </Wrapper >
    );
}

const Wrapper = styled(Block)`
    position: relative;
`;

const Item = styled(Block)`
    
`;

const Plus = styled(SvgIconPlus)``;

const Bar = styled(Block)`
    position: relative;
    cursor: pointer;
    background-color: ${colors.purple02};

    ${Plus} * {
        stroke: white;
    }

    :hover {
        background-color: ${colors.purple01};

        ${Plus} * {
            stroke: white;
        }
    }
`;

const OptionsList = styled(Block)`
    position: absolute;
    top: 16px;
    right: 20px;
`;

const OptionsListItem = styled(Block)`
    cursor: pointer;
    padding: 0 10px;
`;

export default React.memo(ListInput);