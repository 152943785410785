import { Field } from "formik";
import React from "react";
import { DefaultEditor } from 'react-simple-wysiwyg';
import styled from 'styled-components';
import Block from '../Block';
import Text from '../Text';

const Component = ({ label, name, value, setFieldValue }) => {
    return (
        <>
            {label && <Text size={14} height={20} mb={8}>{label}</Text>}
            <Editor
                placeholder={label}
                value={value || ""}
                onChange={(e) => {
                    setFieldValue(name, e.target.value);
                }}
            />
        </>
    )
}

function HtmlEditorSimple(
    {
        label,
        name,
        error,
        onBlur,
    }
) {
    return (
        <BlockInput>
            <Field name={name}>
                {({ field: { value }, form: { setFieldValue } }) => {
                    return (<Component
                        label={label}
                        name={name}
                        value={value}
                        setFieldValue={setFieldValue}
                        onBlur={onBlur}
                    />)
                }}
            </Field>
            {error &&
                <Block mt={4}>
                    <Text
                        size={10}
                        height={12}
                        color="greyDark"
                    >
                        {error}
                    </Text>
                </Block>
            }
        </BlockInput>
    );
}

export default React.memo(HtmlEditorSimple);

const BlockInput = styled(Block)`
    position: relative;
`;

const Editor = styled(DefaultEditor)`
    ol {
        display: block;
        list-style-type: decimal;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }

    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }
`;