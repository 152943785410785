import * as React from "react"

function IconCandidatures({ color = "#55F", ...props }) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx={8} cy={4} r={2} stroke={color} />
            <path
                d="M8 8c-2.21 0-4 1.612-4 3.6V14h8v-2.4C12 9.612 10.21 8 8 8z"
                stroke={color}
            />
            <path d="M8 8v4" stroke={color} strokeLinejoin="round" />
        </svg>
    )
}

export default IconCandidatures
