import React from 'react';
import styled from 'styled-components';
import { Block, Text } from '../../../../components/layout';
import colors from '../../../../utils/colors';

function ButtonAction({ icon, text, children, onClick }) {
    return (
        <Wrapper row center middle pointer onClick={onClick}>
            {!children &&
                <>
                    {!!icon && <Block mr={(!!icon && !!text) ? 8 : 0}>{icon}</Block>}
                    {!!text && <Block>
                        <Text size={14} height={20} style={{ whiteSpace: "nowrap" }}>{text}</Text>
                    </Block>}
                </>
            }
            {children}
        </Wrapper>
    );
}

const Wrapper = styled(Block)`
    height: 52px;
    padding: 18px 24px;
    border-left: 1px solid ${colors.greyAlmostWhite};
`;

export default ButtonAction;