import * as React from "react"

function IconCvDownload(props) {
    return (
        <svg
            width={17}
            height={16}
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M9 14H3V2h10v2M16 12l-3 2-3-2M13 13.5V7" stroke="#35383B" />
            <path d="M11 5H5M9 7H5" stroke="#35383B" strokeLinejoin="round" />
        </svg>
    )
}

export default IconCvDownload
