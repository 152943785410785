import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Header, Modal, ModalThatSlides, Table } from '../../components';
import { Search } from '../../components/Header/components';
import { Block, Button, SelectInput, Text, TextInput } from '../../components/layout';
import ButtonAction from '../../components/Table/components/ButtonAction';
import { SvgIconEdit, SvgIconModalDelete, SvgIconTrash } from '../../svgs';
import { store } from '../../utils/store';
import validateFormSchema from '../../utils/validateFormSchema';

function Articles() {
    const { language } = useContext(store);
    const history = useHistory();
    const paginate = useRef(false);
    const [search, setSearch] = useState("");
    const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
    const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(null);

    const [categories, setCategories] = useState([]);
    const [modalCategoriesIsOpen, setModalCategoriesIsOpen] = useState(false);
    const [modalCategoryUpdateIsOpen, setModalCategoryUpdateIsOpen] = useState(null);
    const [modalCategoryDeleteIsOpen, setModalCategoryDeleteIsOpen] = useState(null);

    const [result, setResult] = useState({ edges: [], pageInfo: { hasNextPage: false }, totalCount: 0 });
    const [templates, setTemplates] = useState([]);

    const [queryArticles, { loading }] = useLazyQuery(
        gql`
            query ($first: Int, $after: String, $search: String) {
                articles(first: $first, after: $after, search: $search) {
                    edges {
                        node {
                            id
                            title
                            slug
                            date
                        }
                        cursor
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                    totalCount
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.articles) {
                    setResult({
                        edges: paginate.current ? [...result.edges, ...data.articles.edges] : data.articles.edges,
                        pageInfo: data.articles.pageInfo,
                        totalCount: data.articles.totalCount
                    });
                    paginate.current = false;
                }
            }
        }
    );

    const [mutationArticleCreate, { loading: loadingArticleCreate }] = useMutation(
        gql`
            mutation articleCreate($slug: String!, $template: ID!) {
                articleCreate(slug: $slug, template: $template) {
                    id
                    title
                    slug
                    date
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.articleCreate) {
                    history.push(`/editor/article/${data.articleCreate.id}`);
                }
            }
        }
    );

    const [mutationArticleDelete, { loading: loadingArticleDelete }] = useMutation(
        gql`
            mutation articleDelete($id: ID!) {
                articleDelete(id: $id) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.articleDelete) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.articleDelete.id);
                    if (index !== -1) {
                        result.edges.splice(index, 1);
                        setModalDeleteIsOpen(null);
                        setResult([...data]);
                    }
                }
            }
        }
    );

    const [queryTemplates, { loading: loadingTemplates }] = useLazyQuery(
        gql`
            query {
                articlesTemplates {
                    id
                    name
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.articlesTemplates) {
                    setTemplates(data.articlesTemplates.map((template) => ({ value: template.id, label: template.name })));
                }
            }
        }
    );

    const [queryCategories, { loading: loadingCategories }] = useLazyQuery(
        gql`
            query {
                categories {
                    id
                    name
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.categories) {
                    setCategories(data.categories);
                }
            }
        }
    );

    const [mutationCategoryCreate, { loading: loadingCategoryCreate }] = useMutation(
        gql`
            mutation categoryCreate($name: String!) {
                categoryCreate(name: $name) {
                    id
                    name
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.categoryCreate) {
                    queryCategories();
                }
            }
        }
    );

    const [mutationCategoryUpdate, { loading: loadingCategoryUpdate }] = useMutation(
        gql`
            mutation categoryUpdate($id: ID!, $name: String) {
                categoryUpdate(id: $id, name: $name) {
                    id
                    name
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.categoryUpdate) {
                    setModalCategoryUpdateIsOpen(null);
                    queryCategories();
                }
            }
        }
    );

    const [mutationCategoryDelete, { loading: loadingCategoryDelete }] = useMutation(
        gql`
            mutation categoryDelete($id: ID!) {
                categoryDelete(id: $id) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.categoryDelete) {
                    const index = categories.findIndex((category) => category.id === data.categoryDelete.id);
                    if (index !== -1) {
                        categories.splice(index, 1);
                        setModalCategoryDeleteIsOpen(null);
                        setCategories([...categories]);
                    }
                }
            }
        }
    );

    const requestData = (params = { next: false }) => {
        const { first, after, next } = params;

        if (next) {
            paginate.current = true;
        }

        queryArticles({
            variables: {
                first: first || 20,
                after: after || undefined,
                search: search !== "" ? search : undefined
            }
        });
    }

    useEffect(() => {
        requestData();
        queryTemplates();
        queryCategories();
    }, [language]);

    useEffect(() => {
        requestData();
    }, [search]);

    return (
        <>
            <Block flex height="100%">
                <Header
                    leftChildren={<Search onChange={(e) => setSearch(e.target.value)} />}
                    rightChildren={
                        <Block row>
                            <Block pointer row middle center pl={48} pr={48} color="greyLightest" onClick={() => setModalCategoriesIsOpen(true)}>
                                <Text size={14} height={28}>Manage Categories</Text>
                            </Block>
                            <Button style={{ minWidth: 309 }} text="Add Article" onClick={() => setModalCreateIsOpen(true)} />
                        </Block>
                    }
                />
                <Block height="100%" mr={20} ml={20} style={{ overflow: "hidden" }}>
                    <Table
                        loading={loading}
                        columns={[
                            { label: "Title", key: "title" },
                            { label: "Slug", key: "slug" },
                            { label: "Date", key: "date" }
                        ]}
                        rows={result.edges.map((edge) => ({ ...edge.node, actions: <><ButtonAction icon={<SvgIconEdit />} text="Edit" onClick={() => history.push(`/editor/article/${edge.cursor}`)} /><ButtonAction icon={<SvgIconTrash />} onClick={() => setModalDeleteIsOpen(edge.cursor)} /></> }))}
                        next={() => requestData({ next: true, after: result.pageInfo.endCursor })}
                        hasMore={result.pageInfo.hasNextPage}
                    />
                </Block>
            </Block>
            <Modal
                title="Create Article"
                isOpen={modalCreateIsOpen}
                onRequestClose={() => setModalCreateIsOpen(false)}
            >
                <Text size={14} height={20}>Article</Text>
                <Block mt={28}>
                    <Formik
                        initialValues={{}}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    slug: yup.string().required(),
                                    template: yup.number().required()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationArticleCreate({ variables: values });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <TextInput name="slug" label="Slug" type="text" onChange={handleChange} onBlur={handleBlur} value={values.slug} error={errors.slug} />
                                            </Block>
                                            <Block mt={12}>
                                                <SelectInput name="template" label="Template" type="text" onBlur={handleBlur} error={errors.template} options={templates} />
                                            </Block>
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingArticleCreate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                width={300}
                title="Delete Article"
                isOpen={!!modalDeleteIsOpen}
                onRequestClose={() => setModalDeleteIsOpen(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to delete this article?</Text>
                <Text size={12} height={14} mt={8}>This action it’s irreversible, it will erase all the data from this article.</Text>
                <Block mt={32}>
                    <Button text="Delete" loading={loadingArticleDelete} onClick={() => mutationArticleDelete({ variables: { id: modalDeleteIsOpen } })} />
                </Block>
            </Modal>
            <ModalThatSlides title="Manage Categories" width="820px" isOpen={modalCategoriesIsOpen} onRequestClose={() => setModalCategoriesIsOpen(false)}>
                <Block>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            name: ""
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required(),
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(false);
                            resetForm({
                                values: {}
                            });
                            mutationCategoryCreate({
                                variables: {
                                    name: values.name
                                }
                            });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autocomplete="off">
                                    <Block flex color="white" pt={16} pb={16} pl={24} pr={24}>
                                        <Text size={14} height={20} mb={4}>New Category</Text>
                                        <Block row mt={12}>
                                            <TextInput label="Name" name="name" onChange={handleChange} onBlur={handleBlur} value={values.name} error={errors.name} />
                                            <Button type="submit" text="Submit" loading={loadingCategoryCreate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
                <Block>
                    <Table
                        loading={loadingCategories}
                        columns={[
                            { label: "Name", key: "name" },
                        ]}
                        rows={categories.map((category) => ({ ...category, actions: <><ButtonAction icon={<SvgIconEdit />} text="Edit" onClick={() => setModalCategoryUpdateIsOpen(category)} /><ButtonAction icon={<SvgIconTrash />} onClick={() => setModalCategoryDeleteIsOpen(category.id)} /></> }))}
                        hasMore={false}
                    />
                </Block>
            </ModalThatSlides>
            <Modal
                title="Update Category"
                isOpen={!!modalCategoryUpdateIsOpen}
                onRequestClose={() => setModalCategoryUpdateIsOpen(null)}
            >
                <Text size={14} height={20}>Category</Text>
                <Block mt={28}>
                    <Formik
                        initialValues={modalCategoryUpdateIsOpen}
                        enableReinitialize
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationCategoryUpdate({ variables: { id: modalCategoryUpdateIsOpen.id, name: values.name } });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autocomplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <TextInput name="name" label="Name" type="text" onChange={handleChange} onBlur={handleBlur} value={values.name} error={errors.name} />
                                            </Block>
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingCategoryUpdate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                width={300}
                title="Delete Category"
                isOpen={!!modalCategoryDeleteIsOpen}
                onRequestClose={() => setModalCategoryDeleteIsOpen(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to delete this type?</Text>
                <Text size={12} height={14} mt={8}>This action it’s irreversible, it will erase all the data from this type.</Text>
                <Block mt={32}>
                    <Button text="Delete" loading={loadingCategoryDelete} onClick={() => mutationCategoryDelete({ variables: { id: modalCategoryDeleteIsOpen } })} />
                </Block>
            </Modal>
        </>
    );
}

export default Articles;