import React from 'react';
import ReactModal from 'react-modal';
import "react-sliding-pane/dist/react-sliding-pane.css";
import styled from 'styled-components';
import { SvgIconClose } from '../../svgs';
import Header from '../Header';
import { Block, Button, Text } from '../layout';

ReactModal.setAppElement('body');

export default function Modal({ isOpen, onRequestClose, title, children, width = 480, ...props }) {
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={{
                overlay: {
                    backgroundColor: "rgb(53,56,59,0.8)"
                },
                content: {
                    maxWidth: width,
                    padding: 0,
                    margin: "auto",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    border: "none",
                    borderRadius: 0,
                    height: "fit-content"
                }
            }}
            {...props}
        >
            <Block height="100%">
                <Header
                    leftChildren={
                        <Block height="100%">
                            <Block width={229} height="100%" middle>
                                <Text size={18} height={32} ml={24}>{title}</Text>
                            </Block>
                        </Block>
                    }
                    rightChildren={
                        <Block row>
                            <Button style={{ width: 52, minWidth: 52, textAlign: "center" }} onClick={onRequestClose}>
                                <SvgIconClose />
                            </Button>
                        </Block>
                    }
                />
                <Block width="100%" height={1} color="greyAlmostWhite" />
                <Content color="white">
                    <Block pl={24} pr={24} pt={24} pb={24}>
                        {children}
                    </Block>
                </Content>
            </Block>
        </ReactModal>
    );
}

const Content = styled(Block)`
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    overflow: hidden;
    position: relative;
    overflow: auto;
`;