import React from 'react';
import { Block } from '../../components/layout';
import ArticlesTemplates from './components/ArticlesTemplates';
import EmailSettings from './components/EmailSettings';
import Languages from './components/Languages';

function Settings() {
    return (
        <Block width="100%" height="100%" ml={10} mr={10} pb={16} style={{ overflowY: "auto" }}>
            <Block mt={16} ml={10} mr={10}>
                <Languages />
            </Block>
            <Block mt={16} ml={10} mr={10}>
                <ArticlesTemplates />
            </Block>
            {/* <Block mt={16} ml={10} mr={10}>
                <EmailSettings />
            </Block> */}
        </Block >
    );
}

export default Settings;