import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import * as yup from 'yup';
import { ModalThatSlides } from '../../../../components';
import { Block, Button, Group, HtmlEditorSimple, ListInput, Section, StatusInput, Text, TextInput } from '../../../../components/layout';
import { getClientToken } from '../../../../utils/client';
import colors from '../../../../utils/colors';
import toast from '../../../../utils/toast';
import validateFormSchema from '../../../../utils/validateFormSchema';

function ModalUpdate({ candidatureId, isOpen, onRequestClose, onUpdateData }) {
    let submitForm = null;

    const [fetchingComplete, setFetchingComplete] = useState(false);
    const [fetchingPDC, setFetchingPDC] = useState(false);

    const [jobOffer, setJobOffer] = useState(null);
    const [status, setStatus] = useState(null);
    const [personal_name, setPersonalName] = useState(null);
    const [personal_nationality, setPersonalNationality] = useState(null);
    const [personal_birthdate, setPersonalBirthdate] = useState(null);
    const [personal_gender, setPersonalGender] = useState(null);
    const [personal_qualifications, setPersonalQualifications] = useState(null);
    const [contacts_email, setcontactsEmail] = useState(null);
    const [contacts_phone, setcontactsPhone] = useState(null);
    const [contacts_location, setcontactsLocation] = useState(null);
    const [pdc_code, setPdcCode] = useState(null);
    const [interview_details, setinterviewDetails] = useState(null);
    const [interview_personal_development, setinterviewPersonalDevelopment] = useState(null);
    const [interview_salary_expectations, setinterviewSalaryExpectations] = useState(null);
    const [professional_experience, setProfessionalExperience] = useState(null);
    const [teaching_experience, setTeachingExperience] = useState(null);
    const [education, setEducation] = useState(null);
    const [other_courses, setOtherCourses] = useState(null);
    const [technical_skills, setTechnicalSkills] = useState(null);

    const [queryCandidature, { loading }] = useLazyQuery(
        gql`
            query candidature($id: ID!) {
                candidature(id: $id) {
                    id
                    jobOffer {
                        id
                        reference
                        title
                        local
                    }
                    status
                    personal_name
                    personal_nationality
                    personal_birthdate
                    personal_gender
                    personal_qualifications
                    contacts_email
                    contacts_phone
                    contacts_location
                    pdc_code
                    interview_details
                    interview_personal_development
                    interview_salary_expectations
                    professional_experience
                    teaching_experience
                    education
                    other_courses
                    technical_skills
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.candidature) {
                    setJobOffer(data.candidature.jobOffer);
                    setStatus(data.candidature.status);
                    setPersonalName(data.candidature.personal_name);
                    setPersonalNationality(data.candidature.personal_nationality);
                    setPersonalBirthdate(data.candidature.personal_birthdate);
                    setPersonalGender(data.candidature.personal_gender);
                    setPersonalQualifications(data.candidature.personal_qualifications);
                    setcontactsEmail(data.candidature.contacts_email);
                    setcontactsPhone(data.candidature.contacts_phone);
                    setcontactsLocation(data.candidature.contacts_location);
                    setPdcCode(data.candidature.pdc_code);
                    setinterviewDetails(data.candidature.interview_details);
                    setinterviewPersonalDevelopment(data.candidature.interview_personal_development);
                    setinterviewSalaryExpectations(data.candidature.interview_salary_expectations);
                    setProfessionalExperience(data.candidature.professional_experience);
                    setTeachingExperience(data.candidature.teaching_experience);
                    setEducation(data.candidature.education);
                    setOtherCourses(data.candidature.other_courses);
                    setTechnicalSkills(data.candidature.technical_skills);
                }
            }
        }
    );

    const [mutationCandidatureUpdate, { loading: loadingCandidatureUpdate }] = useMutation(
        gql`
            mutation candidatureUpdate(
                $id: ID!,
                $status: CandidatureStatus
                $personal_name: String
                $personal_nationality: String
                $personal_birthdate: String
                $personal_gender: String
                $personal_qualifications: String
                $contacts_email: String
                $contacts_phone: String
                $contacts_location: String
                $pdc_code: String
                $interview_details: String
                $interview_personal_development: String
                $interview_salary_expectations: String
                $professional_experience: Json
                $teaching_experience: Json
                $education: Json
                $other_courses: Json
                $technical_skills: Json
            ) {
                candidatureUpdate(
                    id: $id,
                    status: $status,
                    personal_name: $personal_name,
                    personal_nationality: $personal_nationality,
                    personal_birthdate: $personal_birthdate,
                    personal_gender: $personal_gender,
                    personal_qualifications: $personal_qualifications,
                    contacts_email: $contacts_email,
                    contacts_phone: $contacts_phone,
                    contacts_location: $contacts_location,
                    pdc_code: $pdc_code,
                    interview_details: $interview_details,
                    interview_personal_development: $interview_personal_development,
                    interview_salary_expectations: $interview_salary_expectations,
                    professional_experience: $professional_experience,
                    teaching_experience: $teaching_experience,
                    education: $education,
                    other_courses: $other_courses,
                    technical_skills: $technical_skills
                ) {
                    id
                    jobOffer {
                        id
                        reference
                        title
                        local
                    }
                    status
                    personal_name
                    personal_nationality
                    personal_birthdate
                    personal_gender
                    personal_qualifications
                    contacts_email
                    contacts_phone
                    contacts_location
                    pdc_code
                    interview_details
                    interview_personal_development
                    interview_salary_expectations
                    professional_experience
                    teaching_experience
                    education
                    other_courses
                    technical_skills
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.candidatureUpdate) {
                    setJobOffer(data.candidatureUpdate.jobOffer);
                    setStatus(data.candidatureUpdate.status);
                    setPersonalName(data.candidatureUpdate.personal_name);
                    setPersonalNationality(data.candidatureUpdate.personal_nationality);
                    setPersonalBirthdate(data.candidatureUpdate.personal_birthdate);
                    setPersonalGender(data.candidatureUpdate.personal_gender);
                    setPersonalQualifications(data.candidatureUpdate.personal_qualifications);
                    setcontactsEmail(data.candidatureUpdate.contacts_email);
                    setcontactsPhone(data.candidatureUpdate.contacts_phone);
                    setcontactsLocation(data.candidatureUpdate.contacts_location);
                    setPdcCode(data.candidatureUpdate.pdc_code);
                    setinterviewDetails(data.candidatureUpdate.interview_details);
                    setinterviewPersonalDevelopment(data.candidatureUpdate.interview_personal_development);
                    setinterviewSalaryExpectations(data.candidatureUpdate.interview_salary_expectations);
                    setProfessionalExperience(data.candidatureUpdate.professional_experience);
                    setTeachingExperience(data.candidatureUpdate.teaching_experience);
                    setEducation(data.candidatureUpdate.education);
                    setOtherCourses(data.candidatureUpdate.other_courses);
                    setTechnicalSkills(data.candidatureUpdate.technical_skills);

                    onUpdateData && onUpdateData(data.candidatureUpdate);

                    toast.success(`Saved`);
                }
            }
        }
    );

    useEffect(() => {
        if (candidatureId) {
            queryCandidature({ variables: { id: candidatureId } });
        } else {
            setTimeout(() => {
                setJobOffer(null);
                setStatus(null);
                setPersonalName(null);
                setPersonalNationality(null);
                setPersonalBirthdate(null);
                setPersonalGender(null);
                setPersonalQualifications(null);
                setcontactsEmail(null);
                setcontactsPhone(null);
                setcontactsLocation(null);
                setPdcCode(null);
                setinterviewDetails(null);
                setinterviewPersonalDevelopment(null);
                setinterviewSalaryExpectations(null);
                setProfessionalExperience(null);
                setTeachingExperience(null);
                setEducation(null);
                setOtherCourses(null);
                setTechnicalSkills(null);
            }, 500);
        }
    }, [candidatureId]);

    const exportRequest = (type) => {
        if (type === "pdc") {
            setFetchingPDC(true);
        } else {
            setFetchingComplete(true);
        }

        fetch(`${process.env.REACT_APP_BACKEND_HOST}/pdfs/generate/${candidatureId}/${type}`, {
            method: "GET",
            headers: { "Authorization": getClientToken() }
        })
            .then(res => res.blob())
            .then(blob => {
                var file = window.URL.createObjectURL(blob);
                window.open(file, "_blank");
                if (type === "pdc") {
                    setFetchingPDC(false);
                } else {
                    setFetchingComplete(false);
                }
            })
            .catch(() => {
                if (type === "pdc") {
                    setFetchingPDC(false);
                } else {
                    setFetchingComplete(false);
                }
            });
    }

    return (
        <ModalThatSlides
            title="Update candidature"
            width="820px"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            actions={[
                <Button key="buttonExportAll" color="green01" text="Export all" loading={fetchingComplete} onClick={() => exportRequest('complete')} />,
                <Button key="buttonExportPDC" color="green02" text="Export PDC" loading={fetchingPDC} onClick={() => exportRequest('pdc')} />,
                <Button key="buttonSave" color="purple02" text="Save" loading={loadingCandidatureUpdate} onClick={() => { submitForm(); }} />
            ]}
        >
            <Block>
                {loading && <Block center middle>
                    <ReactLoading type="spin" color={colors.purple02} height={20} width={20} />
                </Block>}
                {(!loading && !!jobOffer) && <Formik
                    enableReinitialize
                    initialValues={{
                        status,
                        personal_name,
                        personal_nationality,
                        personal_birthdate,
                        personal_gender,
                        personal_qualifications,
                        contacts_email,
                        contacts_phone,
                        contacts_location,
                        pdc_code,
                        interview_details,
                        interview_personal_development,
                        interview_salary_expectations,
                        professional_experience,
                        teaching_experience,
                        education,
                        other_courses,
                        technical_skills
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validate={values => {
                        const errors = validateFormSchema(
                            yup.object().shape({
                                status: yup.string().required(),
                                personal_name: yup.string().required(),
                                personal_nationality: yup.string().nullable(),
                                personal_birthdate: yup.string().nullable(),
                                personal_gender: yup.string().nullable(),
                                personal_qualifications: yup.string().nullable(),
                                contacts_email: yup.string().required(),
                                contacts_phone: yup.string().nullable(),
                                contacts_location: yup.string().nullable(),
                                pdc_code: yup.string().nullable(),
                                interview_details: yup.string().nullable(),
                                interview_personal_development: yup.string().nullable(),
                                interview_salary_expectations: yup.string().nullable(),
                                professional_experience: yup.array(yup.object()),
                                teaching_experience: yup.array(yup.object()),
                                education: yup.array(yup.object()),
                                other_courses: yup.array(yup.object()),
                                technical_skills: yup.array(yup.object()),
                            }),
                            values
                        );

                        const errorsLength = Object.keys(errors).length;

                        if (errorsLength > 0) {
                            toast.error(`Error! You have errors in ${errorsLength} element(s)!`);
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        mutationCandidatureUpdate({
                            variables: {
                                id: candidatureId,
                                status: values.status,
                                personal_name: values.personal_name,
                                personal_nationality: values.personal_nationality,
                                personal_birthdate: values.personal_birthdate,
                                personal_gender: values.personal_gender,
                                personal_qualifications: values.personal_qualifications,
                                contacts_email: values.contacts_email,
                                contacts_phone: values.contacts_phone,
                                contacts_location: values.contacts_location,
                                pdc_code: values.pdc_code,
                                interview_details: values.interview_details,
                                interview_personal_development: values.interview_personal_development,
                                interview_salary_expectations: values.interview_salary_expectations,
                                professional_experience: values.professional_experience,
                                teaching_experience: values.teaching_experience,
                                education: values.education,
                                other_courses: values.other_courses,
                                technical_skills: values.technical_skills,
                            }
                        })
                    }}
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        submitForm: innerSubmitForm
                    }) => {
                        submitForm = innerSubmitForm;
                        return (
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <Block flex>
                                    <Block mt={12}>
                                        <Group title="Status">
                                            <StatusInput
                                                name="status"
                                                value={values.status}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.status}
                                                options={[
                                                    { label: "Candidature", value: "CANDIDATURE" },
                                                    { label: "In Analysis", value: "IN_ANALYSIS" },
                                                    { label: "Interview", value: "INTERVIEW" },
                                                    { label: "E. Match", value: "EVIDENCE_MATCH" },
                                                    { label: "Rejected", value: "REJECTED" },
                                                    { label: "Contracted", value: "CONTRACTED" },
                                                ]}
                                            />
                                        </Group>
                                        {jobOffer && <Block mt={24} mb={24}>
                                            <Text size={24} height={32} mb={16} bold>Job Offer</Text>
                                            <Block color="white" pl={24} pr={24} pb={16} pt={16} row space="between">
                                                <Block>
                                                    <Text size={14} height={20}>{jobOffer.reference}</Text>
                                                </Block>
                                                <Block>
                                                    <Text size={14} height={20}>{jobOffer.title}</Text>
                                                </Block>
                                                <Block>
                                                    <Text size={14} height={20}>{jobOffer.local}</Text>
                                                </Block>
                                            </Block>
                                        </Block>}
                                        <Section title="General data">
                                            <Group title="Personal Data">
                                                <TextInput name="personal_name" label="Name" value={values.personal_name} error={errors.personal_name} onChange={handleChange} onBlur={handleBlur} />
                                                <Block pt={12} />
                                                <TextInput name="personal_nationality" label="Nationality" value={values.personal_nationality} error={errors.personal_nationality} onChange={handleChange} onBlur={handleBlur} />
                                                <Block pt={12} />
                                                <TextInput name="personal_birthdate" label="Birthdate" type="date" value={values.personal_birthdate} error={errors.personal_birthdate} onChange={handleChange} onBlur={handleBlur} />
                                                <Block pt={12} />
                                                <TextInput name="personal_gender" label="Gender" value={values.personal_gender} error={errors.personal_gender} onChange={handleChange} onBlur={handleBlur} />
                                                <Block pt={12} />
                                                <TextInput name="personal_qualifications" label="Academic Qualifications" value={values.personal_qualifications} error={errors.personal_qualifications} onChange={handleChange} onBlur={handleBlur} />
                                            </Group>
                                            <Block pt={12} />
                                            <Group title="Contacts">
                                                <TextInput name="contacts_email" label="Email" value={values.contacts_email} error={errors.contacts_email} onChange={handleChange} onBlur={handleBlur} />
                                                <Block pt={12} />
                                                <TextInput name="contacts_phone" label="Phone" value={values.contacts_phone} error={errors.contacts_phone} onChange={handleChange} onBlur={handleBlur} />
                                                <Block pt={12} />
                                                <TextInput name="contacts_location" label="Local" value={values.contacts_location} error={errors.contacts_location} onChange={handleChange} onBlur={handleBlur} />
                                            </Group>
                                            <Block pt={12} />
                                            <Group title="PDC code">
                                                <TextInput name="pdc_code" label="Code" value={values.pdc_code} error={errors.pdc_code} onChange={handleChange} onBlur={handleBlur} />
                                            </Group>
                                            <Block pt={12} />
                                            <Group title="Interview briefing">
                                                <HtmlEditorSimple name="interview_personal_development" label="Candidate summary" value={values.interview_personal_development} error={errors.interview_personal_development} onChange={handleChange} onBlur={handleBlur} />
                                                <Block pt={12} />
                                                <HtmlEditorSimple name="interview_details" label="Interview details" value={values.interview_details} error={errors.interview_details} onChange={handleChange} onBlur={handleBlur} />
                                                <Block pt={12} />
                                                <TextInput name="interview_salary_expectations" label="Salary expectations" value={values.interview_salary_expectations} error={errors.interview_salary_expectations} onChange={handleChange} onBlur={handleBlur} />
                                            </Group>
                                        </Section>
                                        <Section title="Professional Experience">
                                            <ListInput
                                                name="professional_experience"
                                                label="Experience"
                                                value={values.professional_experience}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.professional_experience}
                                                items={[
                                                    { name: "company", label: "Company name", Component: TextInput },
                                                    { name: "position", label: "Position in company", Component: TextInput },
                                                    { name: "duration", label: "Duration of the job", Component: TextInput },
                                                    { name: "activities", label: "Activities in the company", Component: HtmlEditorSimple }
                                                ]}
                                            />
                                        </Section>
                                        <Section title="Teaching Experience">
                                            <ListInput
                                                name="teaching_experience"
                                                label="Experience"
                                                value={values.teaching_experience}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.teaching_experience}
                                                items={[
                                                    { name: "activities", label: "Activities in the company", Component: HtmlEditorSimple }
                                                ]}
                                            />
                                        </Section>
                                        <Section title="Education">
                                            <ListInput
                                                name="education"
                                                label="Education"
                                                value={values.education}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.education}
                                                items={[
                                                    { name: "course", label: "Course of studies", Component: TextInput },
                                                    { name: "institution", label: "Institution of study", Component: TextInput },
                                                    { name: "conclusion", label: "Year of conclusion", Component: TextInput },
                                                ]}
                                            />
                                        </Section>
                                        <Section title="Other Courses">
                                            <ListInput
                                                name="other_courses"
                                                label="Courses"
                                                value={values.other_courses}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.other_courses}
                                                items={[
                                                    { name: "activities", label: "Activities in the company", Component: HtmlEditorSimple }
                                                ]}
                                            />
                                        </Section>
                                        <Section title="Technical Skills">
                                            <ListInput
                                                name="technical_skills"
                                                label="Skill"
                                                value={values.technical_skills}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.technical_skills}
                                                items={[
                                                    { name: "skill", label: "Skill", Component: TextInput },
                                                    { name: "description", label: "Description of the skill", Component: HtmlEditorSimple }
                                                ]}
                                            />
                                        </Section>
                                    </Block>
                                </Block>
                            </form>
                        )
                    }}
                </Formik>}
            </Block>
        </ModalThatSlides>
    );
}

export default ModalUpdate;