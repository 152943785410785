import * as React from "react"

function IconUsers(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx={5.23} cy={5.231} r={1.385} stroke="#5555FF" />
            <circle cx={10.769} cy={3.385} r={1.385} stroke="#5555FF" />
            <path
                d="M5.23 8.461c-1.784 0-3.23 1.24-3.23 2.77v1.846h6.462V11.23c0-1.53-1.447-2.77-3.231-2.77z"
                stroke="#5555FF"
            />
            <path
                d="M7.548 9.179c.123-1.434 1.518-2.564 3.222-2.564 1.784 0 3.23 1.24 3.23 2.77v1.846H8.463"
                stroke="#5555FF"
            />
        </svg>
    )
}

export default IconUsers
