import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { ModalThatSlides } from '../../../../components';
import { Block, Button, Group, HtmlEditorSimple, Section, SelectInput, StatusInput, TextInput } from '../../../../components/layout';
import { store } from '../../../../utils/store';
import toast from '../../../../utils/toast';
import validateFormSchema from '../../../../utils/validateFormSchema';

function ModalCreate({ isOpen, onRequestClose, onCreateData }) {
    const { languages } = useContext(store);

    let submitForm = null;

    const [status, setStatus] = useState(null);
    const [reference, setReference] = useState(null);
    const [translations, setTranslations] = useState(null);
    const [users, setUsers] = useState([]);

    const [mutationJobOfferCreate, { loading: loadingJobOfferCreate }] = useMutation(
        gql`
            mutation jobOfferCreate(
                $status: JobOfferStatus!
                $reference: String!
                $translations: [JobOfferTranslationInput!]!
                $owner: ID
            ) {
                jobOfferCreate(
                    status: $status,
                    reference: $reference,
                    translations: $translations,
                    owner: $owner
                ) {
                    id
                    reference
                    status
                    title
                    local
                    description
                    translations {
                        title
                        local
                        description
                        language {
                            id
                            name
                        }
                    }
                    owner {
                        id
                        name
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.jobOfferCreate) {
                    onCreateData && onCreateData(data.jobOfferCreate);

                    toast.success(`Saved`);

                    onRequestClose && onRequestClose();
                }
            }
        }
    );

    const [queryUsers] = useLazyQuery(
        gql`
            query {
                users(first: 100) {
                    edges {
                        cursor
                        node {
                            id
                            name
                        }
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.users) {
                    setUsers(data.users.edges.map((edge) => ({ label: edge.node.name, value: edge.node.id })));
                }
            }
        }
    );

    useEffect(() => {
        queryUsers();
    }, []);

    useEffect(() => {
        if (!isOpen) {
            setTimeout(() => {
                setStatus(null);
                setReference(null);

                const translationsParsed = {};

                for (let i = 0; i < languages.length; i++) {
                    const language = languages[i];
                    translationsParsed[language.id] = { language };
                }

                setTranslations(translationsParsed);
            }, 500);
        }
    }, [isOpen]);

    return (
        <ModalThatSlides
            title="Create jobOffer"
            width="820px"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            actions={[
                <Button key="buttonSave" color="purple02" text="Save" loading={loadingJobOfferCreate} onClick={() => { submitForm(); }} />
            ]}
        >
            <Block>
                <Formik
                    enableReinitialize
                    initialValues={{
                        status,
                        reference,
                        translations
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validate={values => {
                        const errors = validateFormSchema(
                            yup.object().shape({
                                status: yup.string().required(),
                                reference: yup.string().required(),
                                translations: yup.object()
                            }),
                            values
                        );

                        const errorsLength = Object.keys(errors).length;

                        if (errorsLength > 0) {
                            toast.error(`Error! You have errors in ${errorsLength} element(s)!`);
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        mutationJobOfferCreate({
                            variables: {
                                status: values.status,
                                reference: values.reference,
                                owner: values.owner,
                                translations: Object.keys(values.translations).map((translationKey) => ({
                                    title: values.translations[translationKey].title,
                                    local: values.translations[translationKey].local,
                                    description: values.translations[translationKey].description,
                                    language: translationKey
                                }))
                            }
                        })
                    }}
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        submitForm: innerSubmitForm
                    }) => {
                        submitForm = innerSubmitForm;
                        return (
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <Block flex>
                                    <Block mt={12}>
                                        <Group title="Status">
                                            <StatusInput
                                                name="status"
                                                value={values.status}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.status}
                                                options={[
                                                    { label: "Enabled", value: "ENABLED" },
                                                    { label: "Disabled", value: "DISABLED" }
                                                ]}
                                            />
                                        </Group>
                                        <Group title="General data">
                                            <TextInput name="reference" label="Reference" value={values.reference} error={errors.reference} onChange={handleChange} onBlur={handleBlur} />
                                        </Group>
                                        <Group title="Owner">
                                            <SelectInput label="Owner" name="owner" onBlur={handleBlur} value={values.owner} error={errors.owner} options={users} />
                                        </Group>
                                        {translations && Object.keys(translations).map((translationKey) => {
                                            return (
                                                <Section key={translationKey} title={translations[translationKey].language.name}>
                                                    <Group>
                                                        <TextInput name={`translations[${translationKey}].title`} label="Title" value={values.translations[translationKey].title} error={errors.title} onChange={handleChange} onBlur={handleBlur} />
                                                        <Block pt={12} />
                                                        <TextInput name={`translations[${translationKey}].local`} label="Local" value={values.translations[translationKey].local} error={errors.local} onChange={handleChange} onBlur={handleBlur} />
                                                        <Block pt={12} />
                                                        <HtmlEditorSimple name={`translations[${translationKey}].description`} label="Description" value={values.translations[translationKey].description} error={errors.description} onChange={handleChange} onBlur={handleBlur} />
                                                    </Group>
                                                </Section>
                                            );
                                        })}
                                    </Block>
                                </Block>
                            </form>
                        )
                    }}
                </Formik>
            </Block>
        </ModalThatSlides>
    );
}

export default ModalCreate;