import { Field } from "formik";
import React, { useEffect, useRef, useState } from "react";
import styled, { css } from 'styled-components';
import { SvgIconClose } from "../../../svgs";
import colors from "../../../utils/colors";
import Block from '../Block';
import Text from '../Text';

function SelectInput(
  {
    showAll = false,
    name,
    label,
    options = [],
    isMulti = false,
    error,
    onBlur
  }
) {

  const inputRef = useRef(null);
  const [innerValue, setInnerValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const node = useRef();
  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setShowOptions(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <Field name={name}>
      {({ field: { value }, form: { setFieldValue } }) => {

        const optionClicked = (option) => {
          if (isMulti) {
            let aux = value;
            if (value) {
              aux.push(option.value);
            } else {
              aux = [option.value]
            }
            setFieldValue(name, aux);
          } else {
            setFieldValue(name, option.value);
          }
          setShowOptions(false);
          setInnerValue("");
        }

        const optionRemoveClicked = (optionValue) => {
          if (isMulti) {
            const newValue = value.filter((item) => item !== optionValue);
            setFieldValue(name, newValue);
          } else {
            setFieldValue(name, null);
          }
        }

        return (
          <BlockInput ref={node}>
            <TextComponent
              move={(isFocused || innerValue !== "") ? "true" : undefined}
              size={(isFocused || innerValue !== "") ? 10 : 14}
              height={(isFocused || innerValue !== "") ? 12 : 24}
              color="greyLight"
              onClick={() => inputRef.current.focus()}
            >
              {label}
            </TextComponent>
            <Input
              value={innerValue}
              name={name}
              type="text"
              ref={inputRef}
              onFocus={() => { setIsFocused(true); setShowOptions(true); }}
              onBlur={(e) => { onBlur && onBlur(e); setIsFocused(false); }}
              onChange={(e) => {
                setInnerValue(e.target.value);
              }}
              onKeyDown={(e) => {
                if (isMulti && e.key === "Enter") {
                  e.preventDefault();
                  if (e.target.value !== "") {
                    if (options.length === 0 && (!value || !value.find((item) => item === e.target.value))) {
                      setFieldValue(name, value ? [...value, e.target.value] : [e.target.value]);
                    }
                    setInnerValue("");
                  }
                }
              }}
              error={error}
            />
            {showOptions && options.length > 0 &&
              <Options>
                {options
                  .filter((option) => {
                    if (isMulti && !!value && value.find((item) => item === option.value)) return false;
                    if (innerValue === "") return true;
                    const value_norm = option.value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                    const label_norm = option.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                    return value_norm.includes(innerValue) || label_norm.includes(innerValue);
                  })
                  .slice(0, showAll ? options.length : 3)
                  .map((option, index) => (
                    <div key={index}>
                      <Option row center pointer onClick={(e) => optionClicked(option)}>
                        <Text singleLine size={14} height={24} pl={16} pr={16}>{option.label}</Text>
                      </Option>
                      {index !== options.length - 1 && <Block width="100%" height={1} color="greyAlmostWhite" />}
                    </div>
                  ))}
              </Options>
            }
            {(isMulti && value && value.length > 0) &&
              <SelectedOptions row ml={-4} mr={-4} style={{ flexWrap: "wrap" }}>
                {value.map((optionValue, index) => {
                  const option = options.find((option) => option.value === optionValue);
                  return (
                    <SelectedOption key={index} mt={8} ml={4} mr={4} pl={16} pr={16} row center color="greyNotWhite">
                      <Text singleLine size={12} height={20} color="grey">{option ? option.label : optionValue}</Text>
                      <Block pointer onClick={() => optionRemoveClicked(optionValue)}><SvgIconClose color={colors.grey} /></Block>
                    </SelectedOption>
                  );
                })}
              </SelectedOptions>
            }
            {(!isMulti && value) &&
              <SelectedOptions>
                {(() => {
                  const option = options.find((option) => option.value === value);
                  return (
                    <SelectedOption mt={8} pl={16} pr={16} row center color="greyNotWhite">
                      <Text singleLine size={12} height={20} color="grey">{option ? option.label : value}</Text>
                      <Block pointer onClick={() => optionRemoveClicked()}><SvgIconClose color={colors.grey} /></Block>
                    </SelectedOption>
                  );
                })()}
              </SelectedOptions>
            }
            {error &&
              <Block mt={4}>
                <Text
                  size={10}
                  height={12}
                  color="greyDark"
                >
                  {error}
                </Text>
              </Block>
            }
          </BlockInput>
        );
      }}
    </Field>
  );
}

export default React.memo(SelectInput);

const BlockInput = styled(Block)`
  position: relative;
`;

const Input = styled.input`
  background-color: ${colors.greyNotWhite};
  height: 52px;
  color: ${colors.greyDark};
  font-size: 14px;
  line-height: 24px;
  font-family: "Medium";
  border: 0;
  padding: 20px 16px 8px;
  transition: all 0.4s ease-in-out;
  border-bottom: 1px solid #B2B6B9;
  border-radius: 0;
  :focus{
      border-color: ${colors.greyDark};
  }
  ${({ error }) => error && css`
      border-color: #FF0505;
      color: #FF0505;
  `}
`;

const TextComponent = styled(Text)`
  position: absolute;
  top: 14px;
  left: 16px;
  transition: all 0.2s ease-in-out;
  ${({ move }) => move === "true" && css`
      transform: translateY(-6px);
  `}
`;

const Options = styled(Block)`
  z-index: 1;
  background-color: white;
  position: absolute;
  top: 52px;
  left: 0;
  right: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
`;

const Option = styled(Block)`
  width: 100%;
  height: 52px;

  :hover {
    background-color: ${colors.greyNotWhite};
  }
`;

const SelectedOptions = styled(Block)`
  
`;

const SelectedOption = styled(Block)`
  max-width: 137px;
  height: 36px;
  border: 1px solid ${colors.greyLight};
`;