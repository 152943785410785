import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Header, Modal, Table } from '../../components';
import { Search } from '../../components/Header/components';
import { Block, Button, SelectInput, Text, TextInput } from '../../components/layout';
import ButtonAction from '../../components/Table/components/ButtonAction';
import { SvgIconEdit, SvgIconModalDelete, SvgIconTrash } from '../../svgs';
import HasScope from '../../utils/HasScope';
import { store } from '../../utils/store';
import validateFormSchema from '../../utils/validateFormSchema';

function ContentTypes() {
    const { language } = useContext(store);
    const history = useHistory();
    const paginate = useRef(false);
    const [search, setSearch] = useState("");
    const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
    const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(null);

    const [result, setResult] = useState({ edges: [], pageInfo: { hasNextPage: false }, totalCount: 0 });
    const [templates, setTemplates] = useState([]);

    const [queryContentTypes, { loading }] = useLazyQuery(
        gql`
            query ($first: Int, $after: String, $search: String) {
                contentTypes(first: $first, after: $after, search: $search) {
                    edges {
                        node {
                            id
                            name
                            key
                        }
                        cursor
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                    totalCount
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.contentTypes) {
                    setResult({
                        edges: paginate.current ? [...result.edges, ...data.contentTypes.edges] : data.contentTypes.edges,
                        pageInfo: data.contentTypes.pageInfo,
                        totalCount: data.contentTypes.totalCount
                    });
                    paginate.current = false;
                }
            }
        }
    );

    const [mutationContentTypeCreate, { loading: loadingContentTypeCreate }] = useMutation(
        gql`
            mutation contentTypeCreate($name: String!, $key: String!, $template: ID!) {
                contentTypeCreate(name: $name, key: $key, template: $template) {
                    id
                    name
                    key
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.contentTypeCreate) {
                    history.push(`/editor/content-type/${data.contentTypeCreate.id}`);
                }
            }
        }
    );

    const [mutationContentTypeDelete, { loading: loadingContentTypeDelete }] = useMutation(
        gql`
            mutation contentTypeDelete($id: ID!) {
                contentTypeDelete(id: $id) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.contentTypeDelete) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.contentTypeDelete.id);
                    if (index !== -1) {
                        result.edges.splice(index, 1);
                        setModalDeleteIsOpen(null);
                        setResult([...data]);
                    }
                }
            }
        }
    );

    const [queryTemplates, { loading: loadingTemplates }] = useLazyQuery(
        gql`
            query {
                templates(first: 1000) {
                    edges {
                        node {
                            id
                            name
                        }
                        cursor
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.templates) {
                    setTemplates(data.templates.edges.map((edge) => ({ value: edge.cursor, label: edge.node.name })));
                }
            }
        }
    );

    const requestData = (params = { next: false }) => {
        const { first, after, next } = params;

        if (next) {
            paginate.current = true;
        }

        queryContentTypes({
            variables: {
                first: first || 20,
                after: after || undefined,
                search: search !== "" ? search : undefined
            }
        });
    }

    useEffect(() => {
        requestData();
        queryTemplates();
    }, [language]);

    useEffect(() => {
        requestData();
    }, [search]);

    return (
        <>
            <Block flex height="100%">
                <Header
                    leftChildren={<Search onChange={(e) => setSearch(e.target.value)} />}
                    rightChildren={<HasScope roles={["role.developer"]}><Button style={{ minWidth: 309 }} text="Add Content Type" onClick={() => setModalCreateIsOpen(true)} /></HasScope>}
                />
                <Block height="100%" mr={20} ml={20} style={{ overflow: "hidden" }}>
                    <Table
                        loading={loading}
                        columns={[
                            { label: "Name", key: "name" },
                            { label: "Key", key: "key" }
                        ]}
                        rows={result.edges.map((edge) => ({ ...edge.node, actions: <><ButtonAction icon={<SvgIconEdit />} text="Edit" onClick={() => history.push(`/editor/content-type/${edge.cursor}`)} /><HasScope roles={["role.developer"]}><ButtonAction icon={<SvgIconTrash />} onClick={() => setModalDeleteIsOpen(edge.cursor)} /></HasScope></> }))}
                        next={() => requestData({ next: true, after: result.pageInfo.endCursor })}
                        hasMore={result.pageInfo.hasNextPage}
                    />
                </Block>
            </Block>
            <Modal
                title="Create Content Type"
                isOpen={modalCreateIsOpen}
                onRequestClose={() => setModalCreateIsOpen(false)}
            >
                <Text size={14} height={20}>Content Type</Text>
                <Block mt={28}>
                    <Formik
                        initialValues={{}}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required(),
                                    key: yup.string().required(),
                                    template: yup.number().required()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationContentTypeCreate({ variables: values });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <TextInput name="name" label="Name" type="text" onChange={handleChange} onBlur={handleBlur} value={values.name} error={errors.name} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="key" label="Key" type="text" onChange={handleChange} onBlur={handleBlur} value={values.key} error={errors.key} />
                                            </Block>
                                            <Block mt={12}>
                                                <SelectInput name="template" label="Template" type="text" onBlur={handleBlur} error={errors.template} options={templates} />
                                            </Block>
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingContentTypeCreate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                width={280}
                title="Delete Content Type"
                isOpen={!!modalDeleteIsOpen}
                onRequestClose={() => setModalDeleteIsOpen(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to delete this content type?</Text>
                <Text size={12} height={14} mt={8}>This action it’s irreversible, it will erase all the data from this content type.</Text>
                <Block mt={32}>
                    <Button text="Delete" loading={loadingContentTypeDelete} onClick={() => mutationContentTypeDelete({ variables: { id: modalDeleteIsOpen } })} />
                </Block>
            </Modal>
        </>
    );
}

export default ContentTypes;