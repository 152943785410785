import React from 'react';
import { Block, Text } from '../layout';

function Stats({ label, value }) {
    return (
        <Block row space="between" width="100%" height={52} color="greyDark" pl={24} pr={24} pt={16} pb={16}>
            <Text size={14} height={20} color="white">{label}</Text>
            <Text size={14} height={20} color="white">{value}</Text>
        </Block>
    );
}

export default Stats;