import { gql, useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SvgIconAngleDown, SvgIconSearch } from '../../../../svgs';
import colors from '../../../../utils/colors';
import { Block } from '../../../layout';

const defaultQuery = gql` query { default } `;

function FilterSelect({ searchable, width = "auto", label, value, onChange, options = [], searchQuery = defaultQuery }) {
    const [searchValue, setSearchValue] = useState("");
    const [searchOptions, setSearchOptions] = useState([]);

    const [query] = useLazyQuery(
        searchQuery,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data) {
                    const firstKey = Object.keys(data)[0];
                    setSearchOptions(data[firstKey]);
                }
            }
        }
    );

    useEffect(() => {
        if (searchValue.length >= 1) {
            const timeoutId = setTimeout(() => {
                query({ variables: { value: searchValue } });
            }, 300);
            return () => clearTimeout(timeoutId);
        } else {
            setSearchOptions([]);
        }
    }, [searchValue]);

    useEffect(() => {
        setSearchValue("");
        setSearchOptions([]);
    }, [value]);

    return (
        <Block row middle center height="100%" color="white" style={{ borderRight: `1px solid ${colors.greyAlmostWhite}`, position: "relative" }}>
            {
                !searchable ?
                    <>
                        <Select onChange={(e) => onChange && onChange(e.target.value)} value={value} style={{ minWidth: width }}>
                            <option value="">{label}</option>
                            {options.map((option) => <option key={option.value} value={option.value}>{option.label}</option>)}
                        </Select>
                        <Block style={{ position: "absolute", right: 16 }}>
                            <SvgIconAngleDown />
                        </Block>
                    </>
                    :
                    <>
                        {(() => {
                            if (value) {
                                return (
                                    <Block style={{ position: "relative" }} pl={36}>
                                        <Block style={{ position: "absolute", left: 20, top: 18 }}>
                                            <SvgIconSearch />
                                        </Block>
                                        <InputText style={{ minWidth: width }}>{value.label}</InputText>
                                        <InputOptionClear onClick={() => onChange && onChange(null)}>Clear</InputOptionClear>
                                    </Block>
                                );
                            }
                            else {
                                return (
                                    <Block style={{ position: "relative" }} pl={36}>
                                        <Block style={{ position: "absolute", left: 20, top: 18 }}>
                                            <SvgIconSearch />
                                        </Block>
                                        <Input type="text" placeholder={label} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} style={{ minWidth: width }} />
                                    </Block>
                                );
                            }
                        })()}
                        {(() => {
                            if (searchOptions.length > 0) {
                                return (
                                    <InputOptions>
                                        {searchOptions.map((searchOption) => (
                                            <InputOption key={searchOption.value} onClick={() => onChange && onChange(searchOption)}>{searchOption.label}</InputOption>
                                        ))}
                                    </InputOptions>
                                );
                            }
                        })()}
                    </>
            }
        </Block>
    );
}

const Select = styled.select`
    border: none;
    color: ${colors.grey};
    padding: 16px 44px 16px 12px;
    cursor: pointer;

    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;

    ::-ms-expand {
        display: none;
    }
`;

const Input = styled.input`
    font-family: "Medium";
    height: 52px;
    border: none;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
`;

const InputText = styled.span`
    font-family: "Medium";
    height: 52px;
    border: none;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
`;

const InputOptions = styled.div`
    font-family: "Medium";
    position: absolute;
    display: flex;
    flex-flow: column;
    top: 52px;
    left: 0;
    right: 0;
    background-color: white;
`;

const InputOption = styled.div`
    font-family: "Medium";
    height: 52px;
    border: none;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-top: 1px solid ${colors.greyAlmostWhite};
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const InputOptionClear = styled.div`
    position: absolute;
    top: 19px;
    left: 10px;
    border-radius: 3px;
    background-color: ${colors.purple02};
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 12px;
    padding: 1px 3px;
    cursor: pointer;
`;

export default FilterSelect;