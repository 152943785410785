import { FieldArray } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { SvgIconPlus } from '../../../../../svgs';
import colors from '../../../../../utils/colors';
import { Block, Text } from '../../../../layout';
import ListItem from '../ListItem';

function List({
    path,
    title,
    value = [],
    errors = {},
    properties = {
        fields: []
    },
    getElement,
    onChange,
    onBlur
}) {
    
    return (
        <Wrapper color="white">
            <Block>
                <Block pl={24} pr={24} pt={16} pb={16}>
                    <Text size={14} height={20} mb={16}>{title}</Text>
                    <FieldArray
                        name={`${path}`}
                        render={arrayHelpers => {
                            if (value.length === 0) {
                                return (
                                    <Bar
                                        width="100%"
                                        height={28}
                                        middle
                                        center
                                        onClick={() => {
                                            arrayHelpers.insert(0, {});
                                        }}>
                                        <Plus />
                                    </Bar>
                                );
                            }

                            return (
                                value.map((item, index) => {
                                    return (
                                        <Block key={index} mt={index !== 0 ? 20 : 0}>
                                            <ListItem
                                                index={index}
                                                path={`${path}[${index}]`}
                                                value={item}
                                                errors={errors}
                                                properties={properties}
                                                getElement={getElement}
                                                onMoveUp={() => {
                                                    arrayHelpers.move(index, index - 1);
                                                }}
                                                onMoveDown={() => {
                                                    arrayHelpers.move(index, index + 1);
                                                }}
                                                onDelete={() => {
                                                    arrayHelpers.remove(index);
                                                }}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                            />
                                            <Block mt={20}>
                                                <Bar
                                                    width="100%"
                                                    height={28}
                                                    middle
                                                    center
                                                    onClick={() => {
                                                        arrayHelpers.insert(index + 1, {});
                                                    }}>
                                                    <Plus />
                                                </Bar>
                                            </Block>
                                        </Block>
                                    );
                                })
                            );
                        }}
                    />
                </Block>
            </Block>
        </Wrapper >
    );
}

const Wrapper = styled(Block)`
    position: relative;
`;

const Plus = styled(SvgIconPlus)``;

const Bar = styled(Block)`
    position: relative;
    cursor: pointer;
    background-color: ${colors.greyLightest};

    ${Plus} * {
        stroke: ${colors.greyLight};
    }

    :hover {
        background-color: ${colors.grey};

        ${Plus} * {
            stroke: white;
        }
    }
`;

export default React.memo(List);