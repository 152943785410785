import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { Field } from "formik";
import gql from "graphql-tag";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import Modal from 'react-modal';
import styled, { css } from 'styled-components';
import { SvgIconClose, SvgIconSearch, SvgIconUpload } from "../../../svgs";
import colors from "../../../utils/colors";
import Header from "../../Header";
import Block from '../Block';
import Button from "../Button";
import Text from '../Text';
import FileSelectItem from './components/FileSelectItem';

Modal.setAppElement('body');

const Component = ({ label, name, value, setFieldValue, error }) => {
    const hiddenFileInput = useRef(null);
    const paginate = useRef(false);
    const [search, setSearch] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [result, setResult] = useState({ edges: [], pageInfo: { hasNextPage: false }, totalCount: 0 });
    const [fileSelected, setFileSelected] = useState(value);

    const [queryFiles, { loading }] = useLazyQuery(
        gql`
            query files($first: Int, $after: String, $search: String) {
                files(first: $first, after: $after, search: $search) {
                    edges {
                        node {
                            id
                            name
                            mimetype
                            url
                        }
                        cursor
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                    totalCount
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.files) {
                    setResult({
                        edges: paginate.current ? [...result.edges, ...data.files.edges] : data.files.edges,
                        pageInfo: data.files.pageInfo,
                        totalCount: data.files.totalCount
                    });
                    paginate.current = false;
                }
            }
        }
    );

    const [mutateFileCreate, { loading: loadingFileCreate }] = useMutation(
        gql`
            mutation fileCreate($data: Upload!) {
                fileCreate(data: $data) {
                    id
                    name
                    mimetype
                    url
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.fileCreate) {
                    result.edges.unshift({ cursor: data.fileCreate.id, node: data.fileCreate });
                    setResult({ ...result });
                    setFieldValue(name, data.fileCreate);
                }
            }
        }
    );

    const requestData = (params = { next: false }) => {
        const { first, after, next } = params;

        if (next) {
            paginate.current = true;
        }

        queryFiles({
            variables: {
                first: first || 20,
                after: after || undefined,
                search: search !== "" ? search : undefined
            }
        });
    }

    useEffect(() => {
        if (modalIsOpen) {
            requestData();
        }
    }, [search, modalIsOpen]);

    return (
        <>
            <Block>
                {value && <TextComponent
                    size={10}
                    height={12}
                    color="greyLight"
                    onClick={() => setModalIsOpen(true)}
                >
                    {label}
                </TextComponent>}
                <Input
                    pointer
                    middle
                    pl={16}
                    pr={52}
                    error={error}
                    color="greyNotWhite"
                    onClick={() => setModalIsOpen(true)}
                >
                    <Text size={14} height={28} color={!value ? "greyLight" : "greyDark"} pt={!value ? 0 : 8}>{!value ? `Select file` : value.name}</Text>
                    <Icon><SvgIconUpload /></Icon>
                </Input>
                {error &&
                    <Block mt={4}>
                        <Text
                            size={10}
                            height={12}
                            color="greyDark"
                        >
                            {error}
                        </Text>
                    </Block>
                }
                <input ref={hiddenFileInput} name={name} type="file" style={{ display: "none" }} onChange={(e) => {
                    if (e.target.files[0]) {
                        mutateFileCreate({ variables: { data: e.target.files[0] } });
                        hiddenFileInput.current.value = null;
                    }
                }} />
            </Block>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                style={{
                    overlay: {
                        backgroundColor: "rgb(53,56,59,0.8)"
                    },
                    content: {
                        maxWidth: 1028,
                        padding: 0,
                        marginLeft: "auto",
                        marginRight: "auto",
                        left: 0,
                        right: 0,
                        border: "none",
                        borderRadius: 0,
                    }
                }}
            >
                <Block height="100%">
                    <Header
                        leftChildren={
                            <Block height="100%">
                                <Block width={229} height="100%" middle>
                                    <Text size={18} height={32} ml={24}>Select file</Text>
                                </Block>
                            </Block>
                        }
                        rightChildren={
                            <Block row>
                                <Button color="greyLightest" textColor="greyDark" text="Add file" loading={loadingFileCreate} onClick={() => hiddenFileInput.current.click()} />
                                <Button style={{ width: 52, minWidth: 52, textAlign: "center" }} onClick={() => { setModalIsOpen(false); setFileSelected(value) }}>
                                    <SvgIconClose />
                                </Button>
                            </Block>
                        }
                    />
                    <ModalContent color="greyAlmostWhite">
                        <Block pt={32} pl={24} pr={24} pb={24} flex style={{ overflow: "hidden" }}>
                            <Block style={{ position: "relative", flexGrow: 0 }}>
                                <Block style={{ position: "absolute", left: 20, top: 18 }}>
                                    <SvgIconSearch />
                                </Block>
                                <SearchInput type="text" placeholder="Search for files" onChange={(e) => setSearch(e.target.value)} />
                            </Block>
                            <Block flex style={{ flexGrow: 1, overflow: "hidden" }} mt={20} mb={20}>
                                <InfiniteScroll
                                    height="100%"
                                    dataLength={result.edges.length}
                                    next={() => requestData({ next: true, after: result.pageInfo.endCursor })}
                                    hasMore={result.pageInfo.hasNextPage}
                                >
                                    <Block row style={{ flexWrap: "wrap", overflow: "auto" }} ml={-10} mr={-10} mt={-10} mb={-10}>
                                        {result.edges.map((edge) => (
                                            <FileSelectItem
                                                key={edge.cursor}
                                                name={edge.node.name}
                                                mimetype={edge.node.mimetype}
                                                url={edge.node.url}
                                                selected={fileSelected && fileSelected.id === edge.cursor}
                                                onSelect={() => {
                                                    if (!!fileSelected && edge.node.cursor === fileSelected.cursor) {
                                                        setFileSelected(null);
                                                    } else {
                                                        setFileSelected(edge.node)
                                                    }
                                                }}
                                            />
                                        ))}
                                    </Block>
                                </InfiniteScroll>
                            </Block>
                            <Block style={{ flexGrow: 0 }}>
                                <Button text="Submit" onClick={() => {
                                    setFieldValue(name, fileSelected);
                                    setModalIsOpen(false);
                                }} />
                            </Block>
                        </Block>
                    </ModalContent>
                </Block>
            </Modal>
        </>
    )
}

function FileInput(
    {
        label,
        name,
        error
    }
) {
    return (
        <BlockInput>
            <Field name={name}>
                {({ field: { value }, form: { setFieldValue } }) => {
                    return (<Component
                        label={label}
                        name={name}
                        value={value}
                        setFieldValue={setFieldValue}
                        error={error}
                    />)
                }}
            </Field>
        </BlockInput>
    );
}

export default React.memo(FileInput);

const BlockInput = styled(Block)`
    position: relative;
`;

const Input = styled(Block)`
    position: relative;
    height: 52px;
    transition: all 0.4s ease-in-out;
    border-bottom: 1px solid #B2B6B9;
    border-radius: 0;
    :focus{
        border-color: ${colors.greyDark};
    }
    ${({ error }) => error && css`
        border-color: #FF0505;
        color: #FF0505;
    `}
`;

const TextComponent = styled(Text)`
    position: absolute;
    top: 14px;
    left: 16px;
    transition: all 0.2s ease-in-out;
    transform: translateY(-6px);
    cursor: pointer;
`;

const ModalContent = styled(Block)`
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    overflow: hidden;
    position: relative;
    overflow: auto;
`;

const SearchInput = styled.input`
    font-family: "Medium";
    height: 52px;
    max-width: 580px;
    border: none;
    padding-left: 44px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-right: 1px solid ${colors.greyAlmostWhite} ;
`;
const Icon = styled.div`
    position: absolute;
    right: 16px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;