import { Field } from "formik";
import React from "react";
import styled from 'styled-components';
import colors from "../../../utils/colors";
import Block from '../Block';
import Text from '../Text';

function Component(
    {
        name,
        value,
        error,
        setFieldValue,
        options = []
    }
) {
    
    return (
        <BlockInput>
            <Block row>
                {options.map((option) => (
                    <Option key={option.value} center middle selected={option.value === value} onClick={() => setFieldValue(name, option.value)}>
                        <Text size={12} height={15} color={option.value === value ? "white" : "grey"}>{option.label}</Text>
                    </Option>
                ))}

            </Block>
            {error &&
                <Block mt={4}>
                    <Text
                        size={10}
                        height={12}
                        color="greyDark"
                    >
                        {error}
                    </Text>
                </Block>
            }
        </BlockInput>
    );
}

function StatusInput(
    {
        name,
        error,
        options
    }
) {
    return (
        <BlockInput>
            <Field name={name}>
                {({ field: { value }, form: { setFieldValue } }) => {
                    return (<Component
                        name={name}
                        value={value}
                        setFieldValue={setFieldValue}
                        error={error}
                        options={options}
                    />)
                }}
            </Field>
        </BlockInput>
    );
}

export default React.memo(StatusInput);

const BlockInput = styled(Block)`
    position: relative;
    width: 100%;
`;

const Option = styled(Block)`
    cursor: pointer;
    height: 36px;
    padding: 8px 8px;
    background: ${(props) => props.selected ? colors.purple04 : colors.greyNotWhite};
    border: 1px solid ${(props) => props.selected ? colors.purple02 : colors.greyLight};
    width: fit-content;
    margin-right: 13px;
`;