import { gql, useLazyQuery } from '@apollo/react-hooks';
import React, { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Redirect, Route, Switch } from "react-router-dom";
import { Block } from '../../components/layout';
import { Articles, Candidatures, ContentTypes, Files, JobOffers, Pages, Settings, Templates, Users } from '../../pages';
import { setClientLanguage } from '../../utils/client';
import { store } from '../../utils/store';
import useAuth from '../../utils/useAuth';
import Sidebar from './components/Sidebar';

function Dashboard() {
    const { languages, language, setLanguages, setLanguage } = useContext(store);
    const [cookies, setCookie] = useCookies(["backoffice_language"]);

    const [queryLanguages, { loading }] = useLazyQuery(
        gql`
            query {
                languages(first: 1000) {
                    edges {
                        node {
                            id
                            name
                            code
                            default
                        }
                        cursor
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.languages) {
                    setLanguages(data.languages.edges.map((edge) => edge.node));

                    if (!cookies.backoffice_language) {
                        const defaultLanguage = data.languages.edges.find((edge) => !!edge.node.default);
                        if (defaultLanguage) {
                            setCookie("backoffice_language", defaultLanguage.node.code, { path: '/' });
                            setLanguage(defaultLanguage.node);
                        }
                    } else {
                        const currentLanguage = data.languages.edges.find((edge) => edge.node.code == cookies.backoffice_language);
                        if (currentLanguage) {
                            setLanguage(currentLanguage.node);
                        }
                    }
                }
            }
        }
    );

    useEffect(() => {
        if (language) {
            setCookie("backoffice_language", language.code, { path: '/' });
            setClientLanguage(language.code);
        }
    }, [language]);

    useEffect(() => {
        queryLanguages();
    }, []);

    return (
        <Block row flex width="100%" height="100vh" color="greyAlmostWhite" style={{ overflow: "hidden" }}>
            {!!languages && <>
                <Sidebar />
                <Switch>
                    <Route exact path="/dashboard/pages" component={Pages} />
                    <Route exact path="/dashboard/articles" component={Articles} />
                    <Route exact path="/dashboard/job-offers" component={JobOffers} />
                    <Route exact path="/dashboard/candidatures" component={Candidatures} />
                    <Route exact path="/dashboard/templates" component={Templates} />
                    <Route exact path="/dashboard/content-types" component={ContentTypes} />
                    <Route exact path="/dashboard/users" component={Users} />
                    <Route exact path="/dashboard/files" component={Files} />
                    <Route exact path="/dashboard/settings" component={Settings} />
                    <Route
                        exact
                        path="/dashboard"
                        render={() => <Redirect to="/dashboard/pages" />}
                    />
                </Switch>
            </>}
        </Block>
    );
}

export default useAuth(Dashboard);