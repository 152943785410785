import React from 'react';
import Block from '../Block';
import Text from '../Text';

function Group({
    title,
    children
}) {
    return (
        <Block color="white" pl={24} pr={24} pb={16} pt={16} style={{ position: "relative" }}>
            {title && <Text size={14} height={20} mb={16}>{title}</Text>}
            <Block>
                {children}
            </Block>
        </Block>
    );
}

export default Group;